<template>
  <div v-if="tagsCount !== 0" class="tag-container">
    <template v-for="tag in tags" :key="tag.id">
      <button class="tag p-r" v-if="tag.output">
        <div class="close" @click="$emit('deleteTag', tag.name)">
          <icon name="close" />
        </div>
        {{tag.output}}
      </button>
      <template v-if="tag.type ==='checkbox'">
        <button class="tag p-r" v-if="tag.output1">
          <div class="close" @click="$emit('deleteTag', tag.name, 'check1')">
            <icon name="close" />
          </div>
          {{tag.output1}}
        </button>
        <button class="tag p-r" v-if="tag.output2">
          <div class="close" @click="$emit('deleteTag', tag.name, 'check2')">
            <icon name="close" />
          </div>
          {{tag.output2}}
        </button>
        <button class="tag p-r" v-if="tag.output3" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check3')">
            <icon name="close" />
          </div>
          {{tag.output3}}
        </button>
        <button class="tag p-r" v-if="tag.output4" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check4')">
            <icon name="close" />
          </div>
          {{tag.output4}}
        </button>
        <button class="tag p-r" v-if="tag.output5" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check5')">
            <icon name="close" />
          </div>
          {{tag.output5}}
        </button>
        <button class="tag p-r" v-if="tag.output6" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check6')">
            <icon name="close" />
          </div>
          {{tag.output6}}
        </button>
        <button class="tag p-r" v-if="tag.output7" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check7')">
            <icon name="close" />
          </div>
          {{tag.output7}}
        </button>
        <button class="tag p-r" v-if="tag.output8" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check8')">
            <icon name="close" />
          </div>
          {{tag.output8}}
        </button>
        <button class="tag p-r" v-if="tag.output9" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check9')">
            <icon name="close" />
          </div>
          {{tag.output9}}
        </button>
        <button class="tag p-r" v-if="tag.output10" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check10')">
            <icon name="close" />
          </div>
          {{tag.output10}}
        </button>
        <button class="tag p-r" v-if="tag.output11" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check11')">
            <icon name="close" />
          </div>
          {{tag.output11}}
        </button>
        <button class="tag p-r" v-if="tag.output12" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check12')">
            <icon name="close" />
          </div>
          {{tag.output12}}
        </button>
        <button class="tag p-r" v-if="tag.output13" >
          <div class="close" @click="$emit('deleteTag', tag.name, 'check13')">
            <icon name="close" />
          </div>
          {{tag.output13}}
        </button>
      </template>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/icons/icon'

export default {
  name: 'tags',
  components: {
    Icon
  },
  emits: ['deleteTag'],
  props: {
    tags: {
      type: Object
    },
    tagsCount: {
      type: Number
    },
    inputNumber: {
      type: Number
    }
  },
  data () {
    return {
      count: 0
    }
  },
  mounted () {
    for (let i = 0; i < this.tags.length; i++) {
      if (this.tags[i].output || this.tags[i].output1 || this.tags[i].output2 || this.tags[i].output3) {
        this.count += 1
      }
    }
  }
}
</script>

<style lang="scss" src="./tags.scss" scoped />
