<template>
  <div class="layout-1">
    <div class="s-event bonuses-block">
      <div class="s-event-item s-event-intro">
        <div class="bonuses-header">
          <div class="note-wrapper">
            <h2 class="title">Приветственный бонус</h2>
            <note note-position="bottom-to-right">
              Приветственный бонус автоматически начисляется всем Клиентам, которые подключились к бонусной программе.
              <br><br>
              Используйте его, чтобы мотивировать клиентов на подключение.
              <br><br>
              Вот что ваши сотрудники могут говорить
              клиентам: <b>«Получите нашу электронную бонусную карту со скидками до 30%. На балансе сразу будет 100р. –
              сможете прямо сейчас ими рассчитаться и сэкономить. Хотите получить такую карту?».</b>
              30% - настройка «Какой процент счета можно оплатить бонусами»
              100р. – настройка «Приветственный бонус».
              <br><br>
              Если вы не хотите начислять этот бонус, поставьте 0.
            </note>
          </div>
        </div>
        <div class="s-event-wrapper">
          <div class="s-event-left">
            <div class="s-event-hi-number s-event-first-input">
              <div class="input-wrapper" :class="errors.greetBonus && 'p-r'">
                <div class="input-container" :class="errors.greetBonus && 'error-border'">
                  <input class="input" v-model="formData.greetBonus" @input="errors.greetBonus = ''">
                </div>
                <span class="error" v-if="errors.greetBonus">{{ errors.greetBonus }}</span>
              </div>
            </div>
            <div class="s-event-hi-timing">
              <div class="s-event-hi-text">
                бонусов, которые можно потратить
              </div>
              <div class="s-event-hi-select">
                <Multiselect
                  v-model="formData.greetBonusDays"
                  :options="hi"
                  placeholder="Сразу"
                  :canClear="false"
                />
              </div>
            </div>
            <div v-if="formData.greetBonusDays === 'Не сразу'" class="s-event-delete-timer">
              <div class="s-event-delete-preword">через</div>
              <div class="s-event-right-input">
                <div class="input-wrapper" :class="errors.greetBonusDaysCanSpend && 'p-r'">
                  <div class="input-container" :class="errors.greetBonusDaysCanSpend && 'error-border'">
                    <input class="input" v-model="formData.greetBonusDaysCanSpend" @input="errors.greetBonusDaysCanSpend = ''">
                  </div>
                  <span class="error" v-if="errors.greetBonusDaysCanSpend">{{ errors.greetBonusDaysCanSpend }}</span>
                </div>
              </div>
              <div class="s-event-delete-afterword">ч.</div>
            </div>
          </div>
          <div class="s-event-right" style="padding-left: 10px;">
            <!-- Одинаковый контейнер для всех элементов. -->
            <div class="s-event-delete-ask">
              <div class="s-event-delete-preword">Сгорают</div>
              <div class="s-event-right-select">
                <Multiselect
                  v-model="formData.greetBonusBurn"
                  :options="deleteAsk"
                  placeholder="Да"
                  :canClear="false"
                />
              </div>
            </div>
            <div v-if="formData.greetBonusBurn === 'Да'" class="s-event-delete-timer">
              <div class="s-event-delete-preword">через</div>
              <div class="s-event-right-input">
                <div class="input-wrapper" :class="errors.greetBonusBurnDays && 'p-r'">
                  <div class="input-container" :class="errors.greetBonusBurnDays && 'error-border'">
                    <input class="input" v-model="formData.greetBonusBurnDays" @input="errors.greetBonusBurnDays = ''">
                  </div>
                  <span class="error" v-if="errors.greetBonusBurnDays">{{ errors.greetBonusBurnDays }}</span>
                </div>
              </div>
              <div class="s-event-delete-afterword">дн.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="s-event-item s-event-birthday">
        <div class="bonuses-header">
          <div class="note-wrapper">
            <h2 class="title">На День рождения</h2>
            <note note-position="bottom-to-right">
              Бонус на День рождения атвоматически начисляется всем Клиентам на День рождения. Используйте этот бонус, чтобы
              <br><br>
              1) Проявить лояльность по отношению к клиенту в его праздник
              <br>
              2) Пригласить клиента к себе в связи с его днем рождения (например, у вас кафе: "Дарим 500 бонусов и приглашаем вас отпраздновать ваш праздник у нас").
              <br><br>
              Если вы не хотите начислять этот бонус, поставьте 0.
            </note>
          </div>
        </div>
        <div class="s-event-wrapper">
          <div class="s-event-left">
            <div class="s-event-first-left">
              <div class="s-event-hi-number s-event-first-input">
                <div class="input-wrapper" :class="errors.birthdayBonus && 'p-r'">
                  <div class="input-container" :class="errors.birthdayBonus && 'error-border'">
                    <input class="input" v-model="formData.birthdayBonus" @input="errors.birthdayBonus = ''">
                  </div>
                  <span class="error" v-if="errors.birthdayBonus">{{ errors.birthdayBonus }}</span>
                </div>
              </div>
              <div class="s-event-first-aftertext">
                бонусов
              </div>
            </div>
            <div class="s-event-hi-timing" style="margin-left: 85px;">
              <div class="s-event-hi-timing-preword">
                Отправлять за
              </div>
              <div class="s-event-hi-timing-input">
                <div class="input-wrapper" :class="errors.birthdayBonusDays && 'p-r'">
                  <div class="input-container" :class="errors.birthdayBonusDays && 'error-border'">
                    <input class="input" v-model="formData.birthdayBonusDays" @input="errors.birthdayBonusDays = ''">
                  </div>
                  <span class="error" v-if="errors.birthdayBonusDays">{{ errors.birthdayBonusDays }}</span>
                </div>
              </div>
              <div class="s-event-hi-timing-afterword">
                дн.
              </div>
            </div>
          </div>
          <div class="s-event-right">
            <!-- Одинаковый контейнер для всех элементов. -->
            <div class="s-event-delete-ask">
              <div class="s-event-delete-preword">Сгорают</div>
              <div class="s-event-right-select">
                <Multiselect
                  v-model="formData.birthdayBonusBurn"
                  :options="deleteAsk"
                  placeholder="Да"
                  :canClear="false"
                />
              </div>
            </div>
            <div v-if="formData.birthdayBonusBurn === 'Да'" class="s-event-delete-timer">
              <div class="s-event-delete-preword">через</div>
              <div class="s-event-right-input">
                <div class="input-wrapper" :class="errors.birthdayBonusBurnDays && 'p-r'">
                  <div class="input-container" :class="errors.birthdayBonusBurnDays && 'error-border'">
                    <input class="input" v-model="formData.birthdayBonusBurnDays" @input="errors.birthdayBonusBurnDays = ''">
                  </div>
                  <span class="error" v-if="errors.birthdayBonusBurnDays">{{ errors.birthdayBonusBurnDays }}</span>
                </div>
              </div>
              <div class="s-event-delete-afterword">дн.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="s-event-item">
        <div class="bonuses-header">
          <div class="note-wrapper">
            <h2 class="title">Когда долго нет покупок</h2>
            <note note-position="bottom-to-right">
              Бонус "Когда долго нет покупок" автоматически начисляется всем Клиентам, когда они не покупали у вас заданое количество дней. Используйте этот бонус, чтобы вернуть в бизнес потерянных клиентов.
              <br><br>
              Исходите из нормальной переодичности покупок. Если в среднем клиент покупает раз в 3 месяца, отправляйте этот бонус примерно через 180 дней. Не скупитесь ─ сделайте весомое начисление (вы защищены ограничением списания бонусов "Процент от чека бонусами"). Помните, что без супер-предложений вы, скорее всего, этих клиентов уже не увидите.
              <br><br>
              Если вы не хотите начислять этот бонус, поставьте 0.
            </note>
          </div>
        </div>
        <div class="s-event-wrapper">
          <div class="s-event-left">
            <div class="s-event-first-left">
              <div class="s-event-hi-number s-event-first-input">
                <div class="input-wrapper" :class="errors.noShoppingBonus && 'p-r'">
                  <div class="input-container" :class="errors.noShoppingBonus && 'error-border'">
                    <input class="input" v-model="formData.noShoppingBonus" @input="errors.noShoppingBonus = ''">
                  </div>
                  <span class="error" v-if="errors.noShoppingBonus">{{ errors.noShoppingBonus }}</span>
                </div>
              </div>
              <div class="s-event-first-aftertext">
                бонусов, когда нет покупок
              </div>
            </div>
            <div class="s-event-hi-timing" style="margin-left: 67px;">
              <div class="s-event-hi-timing-input">
                <div class="input-wrapper" :class="errors.noShoppingBonusDays && 'p-r'">
                  <div class="input-container" :class="errors.noShoppingBonusDays && 'error-border'">
                    <input class="input" v-model="formData.noShoppingBonusDays" @input="errors.noShoppingBonusDays = ''">
                  </div>
                  <span class="error" v-if="errors.noShoppingBonusDays">{{ errors.noShoppingBonusDays }}</span>
                </div>
              </div>
              <div class="s-event-hi-timing-afterword">
                дн.
              </div>
            </div>
          </div>
          <div class="s-event-right">
            <!-- Одинаковый контейнер для всех элементов. -->
            <div class="s-event-delete-ask">
              <div class="s-event-delete-preword">
                <div class="note-wrapper">
                  Сгорают?
                  <note note-position="bottom-to-right">
                    Бонус будет начисляться постоянно через равные периоды времени, пока клиент не сделает покупку.<br /><br /> Чтобы избежать бесконтрольного накапливания бонусов на счетах клиентов настоятельно рекомендуем НАСТРОИТЬ СГОРАНИЕ этого бонуса.
                  </note>
                </div>
              </div>
              <div class="s-event-right-select">
                <Multiselect
                  v-model="formData.noShoppingBonusBurn"
                  :options="deleteAsk"
                  placeholder="Да"
                  :canClear="false"
                />
              </div>
            </div>
            <div v-if="formData.noShoppingBonusBurn === 'Да'" class="s-event-delete-timer">
              <div class="s-event-delete-preword">через</div>
              <div class="s-event-right-input">
                <div class="input-wrapper" :class="errors.noShoppingBonusBurnDays && 'p-r'">
                  <div class="input-container" :class="errors.noShoppingBonusBurnDays && 'error-border'">
                    <input class="input" v-model="formData.noShoppingBonusBurnDays" @input="errors.noShoppingBonusBurnDays = ''" @blur="onBonusBurnDays">
                  </div>
                  <span class="error" v-if="errors.noShoppingBonusBurnDays">{{ errors.noShoppingBonusBurnDays }}</span>
                </div>
              </div>
              <div class="s-event-delete-afterword">дн.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-status bonuses-block">
      <div class="bonuses-header">
        <div class="note-wrapper">
          <h2 class="title">Статусы</h2>
          <note note-position="bottom-to-right">
            Вы можете создать от 1 до 10 статусов для ваших клиентов.
            <br>
            Статусы в игровой форме мотивируют клиентов на нужные вам действия:
            <br>
            1) максимальные суммы покупок и\или
            <br>
            2) максимальное количество рекомендаций.
            <br>
            После того, как клиент "заработал" новый статус, он получает сообщение об этом.
            <br><br>
            Используйте креативные названия статусов из вашей бизнес-ниши, чтобы вовлекать клиентов в эту игру. Продумайте систему статусов так, чтобы это было интересно для клиекта и не убыточно для вас. Вы можете редактировать статусы соблюдая логику "выше статус-выше достижения".
            <br><br>
            Удаление статусов происходит от старшего к младшемую.
            <br>
            <b>ВАЖНО!</b> При удалении статуса все клиенты, которым присвоен этот статус получат ближайший старший статус. При присвоении статуса по причинен удаления, Клиенты не получат уведомление об изменении статуса.
          </note>
        </div>
        <div v-if="statusesData.data.length < 10" class="s-status-create">
          <button class="link" @click="beforeOpenNewStatus">Создать статус</button>
        </div>
      </div>
      <span class="error" v-if="isStatusError">Необходимо указать хотя бы один статус</span>
      <div class="s-status-table">
        <xTable v-bind="statusesData">
          <template v-slot="{ records }" >
            <td>
              <div class="table-action pr">
                <button class="custom-note-wrap wf ti" @click="editStatus(records.id)">
                  <icon name="edit" class="table-icon" />
                  <div class="custom-note">
                    Редактирование статуса затронет всех пользователей
                  </div>
                </button>
                <button v-if="records.id === biggestStatusId" class="custom-note-wrap wf ti" @click="deleteStatus(records.id)">
                  <icon name="delete" class="table-icon" />
                  <div class="custom-note">
                    При удалении клиенты в этом статусе будут понижены до предыдущего статуса
                  </div>
                </button>
              </div>
            </td>
          </template>
        </xTable>
      </div>
    </div>
    <div class="s-specs bonuses-block">
      <div class="s-specs-wrapper">
        <div class="s-specs-item">
          <div class="s-specs-preword">
            <div class="note-wrapper">
              Бонусы доступны после покупки
              <note note-position="bottom-to-right">
                Используйте блокировку бонусов, если не хотите, чтобы клиенты сразу делил покупку за счет только что начисленных бонусов за предыдущую покупку.
                <br>
                Например, сделал первую покупку, получил бонусы, этими бонусами оплатил вторую покупку через 3 минуты.
                <br><br>
                Также, используйте эту настройку, если у вас часто происходит возврат товара
                Также, используйте эту настройку, если у вас часто происходит возврат товара
              </note>
            </div>
          </div>
          <div class="s-specs-changer">
            <Multiselect
              v-model="formData.bonusAfterBuy"
              :options="hiBonusesPeriod"
              placeholder="Сразу"
              :canClear="false"
            />
          </div>
          <template v-if="formData.bonusAfterBuy === 'Не сразу'">
            <div class="s-specs-afterword">через</div>
            <div class="s-specs-changer-small">
              <div class="input-wrapper" :class="errors.bonusAfterBuyHours && 'p-r'">
                <div class="input-container" :class="errors.bonusAfterBuyHours && 'error-border'">
                  <input class="input" v-model="formData.bonusAfterBuyHours" @input="errors.bonusAfterBuyHours = ''">
                </div>
                <span class="error" v-if="errors.bonusAfterBuyHours">{{ errors.bonusAfterBuyHours }}</span>
              </div>
            </div>
            <div class="s-specs-afterword">часа</div>
          </template>
        </div>
        <div class="s-specs-item">
          <div class="s-specs-preword">
            <div class="note-wrapper">
              Какой процент счета можно оплатить бонусами
              <note note-position="bottom-to-right">
                Эта настройка защищает вас от заведомо убыточных продаж. Вместе с тем, помните, что условия должны быть "вкусными" для клиента.
                <br>
                Найдите точку баланса между вашими интересами и интересами клиентов
              </note>
            </div>
          </div>
          <div class="s-specs-changer">
            <div class="input-wrapper" :class="errors.percentCanUse && 'p-r'">
              <div class="input-container" :class="errors.percentCanUse && 'error-border'">
                <input class="input" v-model="formData.percentCanUse" @blur="e => validatePercent('percentCanUse', e.target.value)">
                <div class="float">%</div>
              </div>
              <span class="error" v-if="errors.percentCanUse">{{ errors.percentCanUse }}</span>
            </div>
          </div>
        </div>
        <div class="s-specs-item">
          <div class="s-specs-preword">
            <div class="note-wrapper">
              Начислять бонусы по номеру телефона
              <note note-position="bottom-to-right">
                Рекомендуем начислять бонусы по номеру телефона.
                <br>
                Не закрывайте такую возможность от клиентов без необходимости
              </note>
            </div>
          </div>
          <div class="s-specs-changer">
            <Multiselect
              v-model="formData.usePhone"
              :options="bonusesMob"
              placeholder="Да"
              :canClear="false"
            />
          </div>
        </div>
        <div class="s-specs-item s-specs-item_second">
          <div class="s-specs-preword">
            <div class="note-wrapper">
              Бонусы на счете сгорают
              <note note-position="bottom-to-right">
                Эти бонусы клиенты заработали своими покупками или рекомендациями. Не рекомендуем использовать сгорание бонусов без необходимости. В большинстве счучаев это не замотивирует клиента к вам вернуться
              </note>
            </div>
          </div>
          <div class="s-specs-changer">
            <Multiselect
              v-model="formData.bonusBurn"
              :options="bonusesDelete"
              placeholder="Да"
              :canClear="false"
            />
          </div>
          <template v-if="formData.bonusBurn === 'Да'">
            <div class="s-specs-afterword">через</div>
            <div class="s-specs-changer-small">
              <div class="input-wrapper" :class="errors.bonusBurnDays && 'p-r'">
                <div class="input-container" :class="errors.bonusBurnDays && 'error-border'">
                  <input class="input" v-model="formData.bonusBurnDays" @input="errors.bonusBurnDays = ''">
                </div>
                <span class="error" v-if="errors.bonusBurnDays">{{ errors.bonusBurnDays }}</span>
              </div>
            </div>
            <div class="s-specs-afterword">дней без покупок</div>
          </template>
        </div>
      </div>
    </div>
    <div class="s-time bonuses-block" v-if="formData.bonusBurn === 'Да'">
      <div class="s-time-desc">Отправить сообщение о сгорании бонусов за</div>
      <div class="s-time-input">
        <div class="input-wrapper" :class="errors.bonusSendFor && 'p-r'">
          <div class="input-container" :class="errors.bonusSendFor && 'error-border'">
            <input class="input" type="number" v-model="formData.bonusSendFor" @blur="onBonusBurnDays" @input="errors.bonusSendFor = ''">
          </div>
          <span class="error" v-if="errors.bonusSendFor">{{ errors.bonusSendFor }}</span>
        </div>
      </div>
      <div class="s-time-afterword">дней</div>
    </div>
    <div class="s-time bonuses-block">
      <div class="s-time-desc">Особые условия бонусной программы</div>
      <div class="s-time-input special-bonus-input">
        <div class="input-wrapper" :class="errors.specialBonusProgram && 'p-r'">
          <div class="input-container" :class="errors.specialBonusProgram && 'error-border'">
            <input class="input" v-model="formData.specialBonusProgram" @blur="e => {errors.specialBonusProgram = e.target.value.length > 500 ? 'Максимальная длина – 500 символов' : ''}">
          </div>
          <span class="error" v-if="errors.specialBonusProgram">{{errors.specialBonusProgram}}</span>
        </div>
      </div>
    </div>
    <div class="s-time bonuses-block">
      <div class="note-wrapper">
        <div class="s-time-desc">Давность последней покупки, после которой клиента считать потерянным</div>
        <note note-position="bottom-to-right">
          Эта настройка используется для корректной работы аналитики.
          <br>
          Напишите, сколько времени должно пройти с момента последней покупки, чтобы вы считали клиента точно потерянным.
        </note>
      </div>
      <div class="s-time-input">
        <div class="input-wrapper" :class="errors.clientLostDays && 'p-r'">
          <div class="input-container" :class="errors.clientLostDays && 'error-border'">
            <input class="input" v-model="formData.clientLostDays" @input="errors.clientLostDays = ''">
          </div>
          <span class="error" v-if="errors.clientLostDays">{{ errors.clientLostDays }}</span>
        </div>
      </div>
      <div class="s-time-afterword">дней</div>
    </div>
    <div class="s-attention bonuses-block">
      <div class="bonuses-header">
        <div class="note-wrapper">
          <h2 class="title">Подозрительная активность</h2>
          <note note-position="bottom-to-right">
            Подозрительная активность ─ активность, которая явно отличается от нормального поведения вашего клиента.
            <br>
            Используйте эти настройки, чтобы выявлять злоупотребления со стороны клиентов и/или сотрудников бизнеса.
          </note>
        </div>
      </div>
      <div class="s-attention-content">
        <div class="columns-2">
          <div class="columns-2-left">
            <div class="x-input-item">
              <div class="input-name">Покупок в день</div>
              <div class="input-content">
                <div class="input-wrapper" :class="errors.suspicionPurchaseDay && 'p-r'">
                  <div class="input-container" :class="errors.suspicionPurchaseDay && 'error-border'">
                    <input class="input" v-model="formData.suspicionPurchaseDay" @input="errors.suspicionPurchaseDay = ''">
                  </div>
                  <span class="error" v-if="errors.suspicionPurchaseDay">{{ errors.suspicionPurchaseDay }}</span>
                </div>
              </div>
              <div class="s-attention-afterword">и более</div>
            </div>
            <div class="x-input-item">
              <div class="input-name">Покупок за 7 дней</div>
              <div class="input-content">
                <div class="input-wrapper" :class="errors.suspicionPurchaseWeek && 'p-r'">
                  <div class="input-container" :class="errors.suspicionPurchaseWeek && 'error-border'">
                    <input class="input" v-model="formData.suspicionPurchaseWeek" @input="errors.suspicionPurchaseWeek = ''">
                  </div>
                  <span class="error" v-if="errors.suspicionPurchaseWeek">{{ errors.suspicionPurchaseWeek }}</span>
                </div>
              </div>
              <div class="s-attention-afterword">и более</div>
            </div>
            <div class="x-input-item">
              <div class="input-name">Покупок за 30 дней</div>
              <div class="input-content">
                <div class="input-wrapper" :class="errors.suspicionPurchaseMonth && 'p-r'">
                  <div class="input-container" :class="errors.suspicionPurchaseMonth && 'error-border'">
                    <input class="input" v-model="formData.suspicionPurchaseMonth" @input="errors.suspicionPurchaseMonth = ''">
                  </div>
                  <span class="error" v-if="errors.suspicionPurchaseMonth">{{ errors.suspicionPurchaseMonth }}</span>
                </div>
              </div>
              <div class="s-attention-afterword">и более</div>
            </div>
          </div>
          <div class="columns-2-right">
            <div class="x-input-item">
              <div class="input-name">Рекомендаций в день</div>
              <div class="input-content">
                <div class="input-wrapper" :class="errors.suspicionRecommendDay && 'p-r'">
                  <div class="input-container" :class="errors.suspicionRecommendDay && 'error-border'">
                    <input class="input" v-model="formData.suspicionRecommendDay" @blur="e => validateCount('suspicionRecommendDay', e.target.value)">
                  </div>
                  <span class="error" v-if="errors.suspicionRecommendDay">{{ errors.suspicionRecommendDay }}</span>
                </div>
              </div>
              <div class="s-attention-afterword">и более</div>
            </div>
            <div class="x-input-item">
              <div class="input-name">Рекомендаций за 7 дней</div>
              <div class="input-content">
                <div class="input-wrapper" :class="errors.suspicionRecommendWeek && 'p-r'">
                  <div class="input-container" :class="errors.suspicionRecommendWeek && 'error-border'">
                    <input class="input" v-model="formData.suspicionRecommendWeek" @blur="e => validateCount('suspicionRecommendWeek', e.target.value)">
                  </div>
                  <span class="error" v-if="errors.suspicionRecommendWeek">{{ errors.suspicionRecommendWeek }}</span>
                </div>
              </div>
              <div class="s-attention-afterword">и более</div>
            </div>
            <div class="x-input-item">
              <div class="input-name">Рекомендаций за 30 дней</div>
              <div class="input-content">
                <div class="input-wrapper" :class="errors.suspicionRecommendMonth && 'p-r'">
                  <div class="input-container" :class="errors.suspicionRecommendMonth && 'error-border'">
                    <input class="input" v-model="formData.suspicionRecommendMonth" @blur="e => validateCount('suspicionRecommendMonth', e.target.value)">
                  </div>
                  <span class="error" v-if="errors.suspicionRecommendMonth">{{ errors.suspicionRecommendMonth }}</span>
                </div>
              </div>
              <div class="s-attention-afterword">и более</div>
            </div>
          </div>
        </div>
      </div>
      <div class="s-save">
        <xButton @click="onSendForm">
          Сохранить
        </xButton>
      </div>
    </div>
  </div>
  <pop-up :open="bonusesPopup" :privateView="true" :iconClose="false" :sizer="'pop-small'">
    <h2 class="x-pop-title">Предупреждение!</h2>
    <p class="x-pop-desc-black">Бонус <strong>«Когда долго нет покупок»</strong> будет начисляться постоянно через равные периоды времени, пока клиент не сделает покупку. Вы не настроили сгорание этого бонуса. Это может привести к бесконтрольному накапливанию бонусов на счетах клиентов. Рекомендуем настроить сгорание этого бонуса.</p>
    <div class="x-pop-item">
      <checkboxInput
        :checked="false"
        :name="'Согласен (понимаю все риски)'"
        @isChecked="val => bonusesPopupRiskChange(val)"
      />
    </div>
    <div class="x-pop-footer double">
      <xButton @click="() => {this.bonusesPopup = false}" :additionalClass="'x-button-mr'">Отмена</xButton>
      <xButton @click="onBonusRiskSave" :additionalClass="'x-button-white'" :disabled="!bonusesPopupRisk">Продолжить</xButton>
    </div>
  </pop-up>
  <pop-up :open="popUp" @close="closeStatusPopup" :privateView="true">
    <h2 class="x-pop-title">{{ isEditStatus ? 'Редактирование статуса' : 'Создание статуса' }}</h2>
    <div class="status-description" v-if="lessStatus">
      Предыдущий статус {{ lessStatus.bonusesName }} - {{ lessStatus.bonusRate }};
      <br>
      <template v-if="lessStatus.bonusSum">за покупки от {{ lessStatus.bonusSum }}</template>
      <template v-if="lessStatus.bonusSum && lessStatus.bonusSum">&nbsp;или&nbsp;</template>
      <template v-if="lessStatus.bonusRefferalNumber">за эффективные рекомендации от {{ lessStatus.bonusRefferalNumber }}</template>.
    </div>
    <div class="x-input-item">
      <div class="input-name">Название статуса</div>
      <div class="input-content">
        <div class="input-wrapper" :class="errors.bonusesName && 'p-r'">
          <div class="input-container" :class="errors.bonusesName && 'error-border'">
            <input class="input" v-model="newStatus.bonusesName" maxlength="40" @input="errors.bonusesName = ''">
          </div>
          <span class="error" v-if="errors.bonusesName">{{ errors.bonusesName }}</span>
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Размер бонуса с покупки в процентах</div>
      <div class="input-content">
        <div class="input-wrapper" :class="errors.bonusRate && 'p-r'">
          <div class="input-container" :class="errors.bonusRate && 'error-border'">
            <input class="input" v-model="newStatus.bonusRate" @blur="e => validatePercent('bonusRate', e.target.value)">
            <div class="float">%</div>
          </div>
          <span class="error" v-if="errors.bonusRate">{{ errors.bonusRate }}</span>
        </div>
      </div>
    </div>
    <template v-if="smallestStatusId !== newStatus.id">
      <p class="x-pop-desc__bold">
        Присваивается клиенту автоматически при выполнении им <span class="x-pop-desc__bold-orange">любого из условий:</span>
      </p>
      <div class="x-input-item fix-note" :class="(typeof newStatus.id !== 'undefined' ? newStatus.id : false) === smallestStatusId && 'disabled-status-row'">
        <div class="note-wrapper">
          <div class="input-name">Сумма собственных покупок</div>
          <note note-position="bottom-to-right">
            Если статус присваивается только за эффективные рекомендации, оставьте поле "Сумма собственных покупок" пустым
          </note>
        </div>
        <div class="input-content">
          <div class="input-wrapper" :class="errors.bonusSum && 'p-r'">
            <div class="input-container" :class="errors.bonusSum && 'error-border'">
              <numberFormat
                class="input"
                v-model:value="newStatus.bonusSum"
                :options="formatSum"
                @blur="e => validateSum('bonusSum', e.target.value)"
              ></numberFormat>
              <div class="float">р.</div>
            </div>
            <span class="error" v-if="errors.bonusSum">{{ errors.bonusSum }}</span>
          </div>
        </div>
      </div>
      <div class="x-input-item fix-note" :class="(typeof newStatus.id !== 'undefined' ? newStatus.id : false) === smallestStatusId && 'disabled-status-row'">
        <div class="note-wrapper">
          <div class="input-name">Кол-во эффективных рекомендаций</div>
          <note note-position="bottom-to-right">
            Эффективная рекомендация ─ это когда друг по рекомендации клиента пришел в бизнес и сделал первую покупку
            <br>
            <br>
            Если статус присваивается только за собственные покупки, оставьте поле "Количество эффективных рекомендаций" пустым.
          </note>
        </div>
        <div class="input-content">
          <div class="input-wrapper" :class="errors.bonusRefferalNumber && 'p-r'">
            <div class="input-container" :class="errors.bonusRefferalNumber && 'error-border'">
              <input class="input" v-model="newStatus.bonusRefferalNumber" @blur="e => validateCount('bonusRefferalNumber', e.target.value)">
            </div>
            <span class="error" v-if="errors.bonusRefferalNumber">{{ errors.bonusRefferalNumber }}</span>
          </div>
        </div>
      </div>
    </template>
    <span class="error" v-if="statusError">{{ statusError }}</span>
    <xButton additionalClass="mt-20" @onClick="saveStatus">Сохранить</xButton>
  </pop-up>
  <pop-up :open="popUpMessages" @close="popUpMessages = !popUpMessages" sizer="pop-small">
    <h2 class="x-pop-title">Сообщения изменились</h2>
    <p class="x-pop-desc-black">После сохранения зайдите на вкладку <b><router-link to="/admin/settings/service" class="link">Сообщения / Сервисные</router-link></b> сообщения и посмотрите, какие сообщения будут отправляться клиентам. При необходимости скорректируйте сообщения.</p>
    <div class="x-pop-footer double">
      <xButton additionalClass="x-button-white" @onClick="popUpMessages = false">Закрыть</xButton>
      <xButton additionalClass="x-button-mr" @onClick="this.$router.push('/admin/settings/service')">Перейти в сообщения</xButton>
    </div>
  </pop-up>
</template>

<script>
import numberFormat from '@/components/numberFormat'
import debounce from 'lodash.debounce'
import xTable from '@/components/xTable'
import note from '@/components/note'
import xButton from '@/components/xButton'
import popUp from '@/components/popUp'
import checkboxInput from '@/components/checkboxInput'

const validatePercent = debounce((val, min, successCall, errorCall) => {
  const minVal = min !== false ? min : 0.01

  const toFloat = parseFloat(val.toString().replace(',', '.'))
  if (isNaN(toFloat)) {
    return errorCall('Значение введено с ошибкой')
  }

  if (toFloat < minVal) {
    return errorCall('Значение не может быть меньше ' + minVal)
  }
  if (toFloat > 100.00) {
    return errorCall('Значение не может быть больше 100')
  }

  const splitted = toFloat.toString().split('.')
  if (splitted.length === 2) {
    if (splitted[1].length >= 2) {
      splitted[1] = splitted[1].slice(0, 2)
    } else {
      splitted[1] = splitted[1] + '0'
    }
  }
  successCall(splitted.join('.'))
}, 0)

const validateSum = debounce((val, min, successCall, errorCall) => {
  const minVal = min !== false ? min : 1

  const toInt = parseInt(val.replace(/\D/gmu, ''))
  if (isNaN(toInt)) {
    return errorCall('Значение введено с ошибкой')
  }

  if (toInt < minVal) {
    return errorCall('Значение не может быть меньше ' + minVal)
  }
  if (toInt > 1000000000) {
    return errorCall('Значение не может быть больше 1 000 000 000')
  }

  successCall(toInt)
}, 0)

const validateCount = debounce((val, successCall, errorCall) => {
  const toInt = parseInt(val)
  if (isNaN(toInt)) {
    return errorCall('Значение введено с ошибкой')
  }

  if (toInt < 1) {
    return errorCall('Значение не может быть меньше 1')
  }
  if (toInt > 1000) {
    return errorCall('Значение не может быть больше 1 000')
  }

  successCall(toInt)
}, 0)

export default {
  name: 'settingsBonuses',
  components: {
    xTable,
    note,
    xButton,
    popUp,
    numberFormat,
    checkboxInput
  },
  data () {
    return {
      referralBonusSum: 0,
      formatSum: {
        decimal: '',
        thousand: ' ',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },
      formError: '',
      statusError: '',
      showReferal: true,
      tabType: 'bonus',
      popUp: false,
      popUpMessages: false,
      bonusesPopup: false,
      bonusesPopupRisk: false,
      isEditStatus: false,
      newStatus: {
        bonusesName: '',
        bonusRate: '',
        bonusSum: '',
        bonusRefferalNumber: ''
      },
      hi: [
        'Сразу',
        'Не сразу'
      ],
      deleteAsk: [
        'Да',
        'Нет'
      ],
      hiBonusesPeriod: [
        'Сразу',
        'Не сразу'
      ],
      bonusesMob: [
        'Да',
        'Нет'
      ],
      bonusesDelete: [
        'Да',
        'Нет'
      ],
      statusesData: {
        theads: 1,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [
          {
            id: 0,
            bonusesName: 'Добро пожаловать',
            bonusRate: '3%',
            bonusSum: '─',
            bonusRefferalNumber: '─'
          }
        ],
        wordChecker: true,
        optionsX: [
          { name: 'Название статуса', value: 'bonusesName', id: 0 },
          { name: 'Размер бонуса с покупки', value: 'bonusRate', id: 1 },
          { name: 'Когда сумма покупок от', value: 'bonusSum', id: 2 },
          { name: 'Когда рекомендаций от', value: 'bonusRefferalNumber', id: 3 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Название статуса', 'Размер бонуса с покупки', 'Когда сумма покупок от', 'Когда рекомендаций от'],
        namesForHeaderX: []
      },
      isFormLoaded: false,
      formData: {
        id: 0,
        name: '',
        greetBonus: '',
        greetBonusDays: 'Сразу',
        greetBonusDaysCanSpend: '',
        greetBonusBurn: 'Нет',
        greetBonusBurnDays: '',
        birthdayBonus: '',
        birthdayBonusDays: '',
        birthdayBonusBurn: 'Нет',
        birthdayBonusBurnDays: '',
        noShoppingBonus: '',
        noShoppingBonusDays: '',
        noShoppingBonusBurn: 'Да',
        noShoppingBonusBurnDays: '',
        bonusAfterBuy: 'Не сразу',
        bonusAfterBuyHours: '',
        percentCanUse: '',
        usePhone: 'Да',
        bonusBurn: 'Нет',
        bonusBurnDays: '',
        bonusSendFor: '',
        specialBonusProgram: '',
        clientLostDays: '',
        suspicionPurchaseDay: '',
        suspicionPurchaseWeek: '',
        suspicionPurchaseMonth: '',
        suspicionRecommendDay: '',
        suspicionRecommendWeek: '',
        suspicionRecommendMonth: ''
      },
      errors: {
        greetBonusDaysCanSpend: '',
        percentCanUse: '',
        bonusesName: '',
        bonusRate: '',
        bonusSum: '',
        bonusRefferalNumber: '',
        suspicionRecommendDay: '',
        suspicionRecommendWeek: '',
        suspicionRecommendMonth: '',
        suspicionPurchaseDay: '',
        suspicionPurchaseMonth: '',
        name: '',
        greetBonus: '',
        greetBonusBurnDays: '',
        birthdayBonus: '',
        birthdayBonusBurnDays: '',
        noShoppingBonus: '',
        noShoppingBonusDays: '',
        noShoppingBonusBurnDays: '',
        bonusAfterBuyHours: '',
        birthdayBonusDays: '',
        suspicionPurchaseWeek: '',
        specialBonusProgram: '',
        clientLostDays: '',
        bonusBurnDays: '',
        bonusSendFor: ''
      }
    }
  },
  watch: {
    'formData.name' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.greetBonus' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.greetBonusDays' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.greetBonusDaysCanSpend' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.greetBonusBurn' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.greetBonusBurnDays' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.birthdayBonus' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.birthdayBonusDays' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.birthdayBonusBurn' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.birthdayBonusBurnDays' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.noShoppingBonus' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.noShoppingBonusDays' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.noShoppingBonusBurn' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.noShoppingBonusBurnDays' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.bonusAfterBuy' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.bonusAfterBuyHours' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.percentCanUse' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.usePhone' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.bonusBurn' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.bonusBurnDays' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.bonusSendFor' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.specialBonusProgram' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.clientLostDays' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.suspicionPurchaseDay' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.suspicionPurchaseWeek' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.suspicionPurchaseMonth' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.suspicionRecommendDay' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.suspicionRecommendWeek' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.suspicionRecommendMonth' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    }
  },
  computed: {
    isCanSaveNewStatus () {
      return !this.errors.bonusesName.length && !this.errors.bonusRate.toString().length && !this.errors.bonusSum.toString().length && !this.errors.bonusRefferalNumber.toString().length
    },
    isStatusError () {
      return !this.statusesData.data.length
    },
    isHaveErrors () {
      const errors = []
      for (const key in this.errors) {
        if (!['greetBonusBurnDays', 'birthdayBonusBurnDays', 'noShoppingBonusBurnDays', 'bonusBurnDays', 'bonusAfterBuyHours'].includes(key)) {
          errors.push(this.errors[key])
        }
      }
      return !!errors.join('').length || !!this.isStatusError
    },
    isHaveEmptyFields () {
      for (const key in this.formData) {
        if (!this.formData[key].toString().length) {
          let checkField = false

          switch (key) {
            case 'greetBonusBurnDays':
              checkField = 'greetBonusBurn'
              break
            case 'birthdayBonusBurnDays':
              checkField = 'birthdayBonusBurn'
              break
            case 'noShoppingBonusBurnDays':
              checkField = 'noShoppingBonusBurn'
              break
            case 'bonusBurnDays':
              checkField = 'bonusBurn'
              break
          }

          if (
            (checkField && this.formData[checkField] !== 'Да') ||
            (key === 'bonusAfterBuyHours' && this.formData.bonusAfterBuy !== 'Не сразу') ||
            (key === 'greetBonusDaysCanSpend' && this.formData.greetBonusDays !== 'Не сразу') ||
            (key === 'specialBonusProgram')
          ) {
            continue
          }

          return true
        }
      }
      return false
    },
    bonusesForRequest () {
      return this.statusesData.data.map(el => {
        const sum = parseInt(el.bonusSum)
        const recommendCount = parseInt(el.bonusRefferalNumber)
        const bonus = parseFloat(el.bonusRate)
        const id = parseInt(el.id)

        return {
          id,
          name: el.bonusesName,
          sum: isNaN(sum) ? '' : sum,
          recommendCount: isNaN(recommendCount) ? '' : recommendCount,
          bonus: isNaN(bonus) ? '' : bonus
        }
      })
    },
    biggestStatusId () {
      let biggestId = this.statusesData.data[0].id
      this.statusesData.data.map(el => {
        if (el.id > biggestId) {
          biggestId = el.id
        }

        return el;
      })
      return biggestId
    },
    smallestStatusId () {
      let smallesId = this.statusesData.data[0].id
      this.statusesData.data.map(el => {
        if (el.id < smallesId) {
          smallesId = el.id
        }

        return el;
      })
      return smallesId
    },
    lessStatus () {
      return !this.isEditStatus
        ? this.statusesData.data[this.statusesData.data.length - 1]
        : (this.newStatus.id !== this.smallestStatusId
            ? this.statusesData.data[this.getPrevStatusI(this.newStatus.id)]
            : false)
    }
  },
  methods: {
    onBonusBurnDays () {
      if (this.formData.noShoppingBonusBurnDays) {
        if (this.formData.bonusSendFor) {
          if (parseInt(this.formData.noShoppingBonusBurnDays) < parseInt(this.formData.bonusSendFor)) {
            this.formData.bonusSendFor = this.formData.noShoppingBonusBurnDays
          }
        }
      } else {
        this.formData.bonusSendFor = ''
      }
    },
    scrollToErrors () {
      let needFirstTab = false
      for (const key in this.errors) {
        if (
          !['greetBonusBurnDays', 'birthdayBonusBurnDays', 'noShoppingBonusBurnDays', 'bonusAfterBuyHours'].includes(key) &&
          this.errors[key].length
        ) {
          needFirstTab = true
          break
        }
      }

      if (needFirstTab) {
        this.tabType = 'bonus'
      }

      setTimeout(() => {
        const firstErrElem = window.document.querySelector('.error-border')

        if (firstErrElem) {
          const offset = window.scrollY + firstErrElem.getBoundingClientRect().top - 100
          window.scroll({
            top: offset < 0 ? 0 : offset,
            left: 0,
            behavior: 'smooth'
          })
        }
      }, 100)
    },
    getNextStatusI (curId) {
      const sortedIdsToI = {}
      this.statusesData.data.map((val, i) => {
        sortedIdsToI[val.id] = i
        return val;
      })
      let flag = false
      for (const id in sortedIdsToI) {
        if (flag) {
          return sortedIdsToI[id]
        }
        parseInt(id) === curId && (flag = true)
      }
      return false
    },
    getPrevStatusI (curId) {
      let sortedIdsToI = 0

      this.statusesData.data.map((val, i) => {
        if (curId === val.id) {
          sortedIdsToI = i - 1
        }
        return val;
      })

      return sortedIdsToI
    },
    checkEmptyFields () {
      const checkThisFields = [
        'name',
        'greetBonus',
        'greetBonusBurnDays',
        'birthdayBonus',
        'birthdayBonusBurnDays',
        'noShoppingBonus',
        'noShoppingBonusDays',
        'noShoppingBonusBurnDays',
        'bonusAfterBuyHours',
        'birthdayBonusDays',
        'clientLostDays',
        'bonusBurnDays',
        'percentCanUse',
        'suspicionRecommendDay',
        'suspicionRecommendWeek',
        'suspicionRecommendMonth',
        'suspicionPurchaseDay',
        'suspicionPurchaseWeek',
        'suspicionPurchaseMonth',
        'bonusAfterBuyHours',
        'greetBonusDaysCanSpend',
        'bonusSendFor'
      ]
      for (const formKey of checkThisFields) {
        if (
          (formKey === 'bonusAfterBuyHours' && this.formData.bonusAfterBuy === 'Не сразу') ||
          (formKey === 'greetBonusDaysCanSpend' && this.formData.greetBonusDays === 'Не сразу') ||
          (formKey === 'bonusSendFor' && this.formData.bonusBurn === 'Да')
        ) {
          this.errors[formKey] = !this.formData[formKey].toString().trim().length
            ? 'Заполните поле'
            : ''
        } else if (formKey !== 'formKey' && formKey !== 'greetBonusDaysCanSpend') {
          this.errors[formKey] = !this.formData[formKey].toString().trim().length
            ? 'Заполните поле'
            : ''
        }
      }
    },
    beforeOpenNewStatus () {
      this.popUp = true
      this.isEditStatus = false
    },
    closeStatusPopup () {
      this.popUp = false
      this.isEditStatus = false
      this.statusError = ''
      this.newStatus.bonusesName = ''
      this.newStatus.bonusRefferalNumber = ''
      this.newStatus.bonusSum = ''
      this.newStatus.bonusRate = ''
      delete this.newStatus.id
    },
    validatePercent (variable, val, min = false) {
      if (val.trim() === '') {
        return
      }
      const replacedVal = val.replace(/%/, '')
      if (variable === 'bonusRate') {
        this.newStatus.bonusRate = replacedVal
      } else {
        this.formData[variable] = replacedVal
      }

      this.errors[variable] = ''
      validatePercent(
        replacedVal,
        min,
        newVal => {
          if (variable === 'bonusRate') {
            this.newStatus.bonusRate = newVal
          } else {
            this.formData[variable] = newVal
          }
        },
        errString => {
          this.errors[variable] = errString
        }
      )
    },
    validateSum (variable, val, min = false) {
      if (val.trim() === '') {
        return
      }
      const replacedVal = val.replace(/р\./, '')
      if (variable === 'bonusSum') {
        this.newStatus.bonusSum = replacedVal
      } else {
        this.formData[variable] = replacedVal
      }

      this.errors[variable] = ''
      if (variable === 'bonusSum') {
        this.errors.bonusRefferalNumber = ''
      }
      validateSum(
        replacedVal,
        min,
        newVal => {
          if (variable === 'bonusSum') {
            this.newStatus.bonusSum = newVal
          } else {
            this.formData[variable] = newVal
          }
        },
        errString => {
          this.errors[variable] = errString
        }
      )
    },
    validateCount (variable, val) {
      if (val.trim() === '') {
        return
      }
      this.errors[variable] = ''
      if (variable === 'bonusRefferalNumber') {
        this.errors.bonusSum = ''
      }
      validateCount(
        val,
        newVal => {
          if (variable === 'bonusRefferalNumber') {
            this.newStatus.bonusRefferalNumber = newVal
          } else {
            this.formData[variable] = newVal
          }
        },
        errString => {
          this.errors[variable] = errString
        }
      )
    },
    saveStatus () {
      this.statusError = ''

      const newState = {
        ...this.newStatus
      }

      if (!this.isCanSaveNewStatus) {
        return
      }

      const newBonusRate = parseInt(newState.bonusRate.replace('%', ''))

      if (this.statusesData.data[this.statusesData.data.length - 1].id === newState.id || !newState.id) {
        const allBonusSum = parseInt(this.formData.referralLevel1) +
          parseInt(this.formData.referralLevel2) +
          parseInt(this.formData.referralLevel3) +
          newBonusRate
        if (allBonusSum > 100) {
          this.$store.commit(
            'popupStore/show',
            {
              text: 'Сумма бонусов по последнему статусу и вознаграждениям за рекомендации не может превышать 100%. Скорректируйте процент начисляемых бонусов.',
              buttonText: 'Закрыть',
              useFullWindow: false
            }
          )
          return
        }
      }

      const lessStatus = !this.isEditStatus ? this.statusesData.data[this.statusesData.data.length - 1] : (newState.id !== this.smallestStatusId ? this.statusesData.data[this.getPrevStatusI(newState.id)] : false)

      if (newState.id !== this.smallestStatusId) {
        if (!this.newStatus.bonusRate.toString().length) {
          this.errors.bonusRate = 'Заполните поле'
        }
        if (!this.newStatus.bonusSum.toString().length && !this.newStatus.bonusRefferalNumber.toString().length) {
          this.errors.bonusSum = 'Заполните поле'
          this.errors.bonusRefferalNumber = 'Заполните поле'
        }
      }
      if (this.errors.bonusRate || this.errors.bonusSum || this.errors.bonusRefferalNumber) {
        return
      }

      if (lessStatus) {
        const lessRateError = (!parseFloat(lessStatus.bonusRate) || (!!newState.bonusRate && parseFloat(lessStatus.bonusRate) < parseFloat(newState.bonusRate)))
        const lessSumError = (!parseInt(lessStatus.bonusSum) || !parseInt(newState.bonusSum) || (!!newState.bonusSum && parseInt(lessStatus.bonusSum) < parseInt(newState.bonusSum)))
        const lessReffError = (!parseInt(lessStatus.bonusRefferalNumber) || !parseInt(newState.bonusRefferalNumber) || (!!newState.bonusRefferalNumber && parseInt(lessStatus.bonusRefferalNumber) < parseInt(newState.bonusRefferalNumber)))
        const moreThanLess = lessRateError && lessSumError && lessReffError

        if (!moreThanLess) {
          this.statusError = 'Условия присвоения старшего статуса должны быть выше, чем в предыдущем статусе'
          !lessRateError && (this.errors.bonusRate = 'Заполните поле корректно')
          !lessSumError && (this.errors.bonusSum = 'Заполните поле корректно')
          !lessReffError && (this.errors.bonusRefferalNumber = 'Заполните поле корректно')
        } else if (this.statusError === 'Условия присвоения старшего статуса должны быть выше, чем в предыдущем статусе') {
          this.statusError = ''
        }
      }

      if (!this.statusError) {
        const moreStatus = !this.isEditStatus ? false : (newState.id !== this.biggestStatusId ? this.statusesData.data[this.getNextStatusI(newState.id)] : false)
        if (moreStatus) {
          const moreRateError = (!parseFloat(moreStatus.bonusRate) || (!!newState.bonusRate && parseFloat(moreStatus.bonusRate) > parseFloat(newState.bonusRate)))
          const moreSumError = (!parseInt(moreStatus.bonusSum) || !parseInt(newState.bonusSum) || (!!newState.bonusSum && parseInt(moreStatus.bonusSum) > parseInt(newState.bonusSum)))
          const moreReffError = (!parseInt(moreStatus.bonusRefferalNumber) || !parseInt(newState.bonusRefferalNumber) || (!!newState.bonusRefferalNumber && parseInt(moreStatus.bonusRefferalNumber) > parseInt(newState.bonusRefferalNumber)))
          const lessThanMore = moreRateError && moreSumError && moreReffError

          if (!lessThanMore) {
            this.statusError = 'Условия присвоения младшего статуса должны быть ниже, чем в следующем статусе'
            !moreRateError && (this.errors.bonusRate = 'Заполните поле корректно')
            !moreSumError && (this.errors.bonusSum = 'Заполните поле корректно')
            !moreReffError && (this.errors.bonusRefferalNumber = 'Заполните поле корректно')
          } else if (this.statusError === 'Условия присвоения младшего статуса должны быть ниже, чем в следующем статусе') {
            this.statusError = ''
          }
        }
      }

      if (!this.isCanSaveNewStatus || this.statusError) {
        return
      }

      this.popUp = false

      const bonusRate = parseFloat(newState.bonusRate)
      const bonusRateSplit = bonusRate.toString().split('.')
      if (bonusRateSplit.length === 2 && bonusRateSplit[1].length === 1) {
        bonusRateSplit[1] += '0'
      }
      const bonusSum = parseInt(newState.bonusSum)
      const bonusRefferalNumber = parseInt(newState.bonusRefferalNumber)

      if (this.isEditStatus) {
        this.isEditStatus = false
        const findedI = this.statusesData.data.findIndex(el => el.id === this.newStatus.id)

        this.statusesData.data[findedI].bonusesName = newState.bonusesName
        this.statusesData.data[findedI].bonusRate = isNaN(bonusRate) ? '─' : (bonusRateSplit.join('.') + '%')
        this.statusesData.data[findedI].bonusSum = isNaN(bonusSum) ? '─' : (bonusSum + 'р.')
        this.statusesData.data[findedI].bonusRefferalNumber = isNaN(bonusRefferalNumber) ? '─' : bonusRefferalNumber
      } else {
        this.statusesData.data.push({
          id: this.biggestStatusId + 1,
          bonusesName: newState.bonusesName,
          bonusRate: isNaN(bonusRate) ? '─' : (bonusRateSplit.join('.') + '%'),
          bonusSum: isNaN(bonusSum) ? '─' : (bonusSum + 'р.'),
          bonusRefferalNumber: isNaN(bonusRefferalNumber) ? '─' : bonusRefferalNumber
        })
      }

      delete this.newStatus.id
      this.newStatus.bonusesName = ''
      this.newStatus.bonusRate = ''
      this.newStatus.bonusSum = ''
      this.newStatus.bonusRefferalNumber = ''
    },
    editStatus (key) {
      this.newStatus = {
        ...this.statusesData.data.find(el => el.id === key)
      }
      this.popUp = true
      this.isEditStatus = true
    },
    deleteStatus (key) {
      this.$store.commit(
        'popupStore/show',
        {
          title: 'Вы удаляете статус',
          text: 'По всем клиентам в этом статусе автоматически произойдет понижение статуса до предыдущего. В данном случае клиенты не получат уведомление об изменении статуса. Подтвердите удаление.',
          buttonText: 'Отмена',
          rollBackButtonText: 'Удалить',
          useFullWindow: false,
          callback: () => {},
          errorCallback: () => {
            const findedI = this.statusesData.data.findIndex(el => el.id === key)
            this.statusesData.data.splice(findedI, 1)
          }
        }
      )
    },
    onBonusRiskSave () {
      this.bonusesPopup = false
      localStorage.setItem('bonus_burn_warning', 'true')
    },
    bonusesPopupRiskChange (val) {
      this.bonusesPopupRisk = val
    },
    onSendForm () {
      this.checkEmptyFields()

      if (this.formData.specialBonusProgram.length > 500) {
        return
      }

      if (this.isHaveErrors || this.isHaveEmptyFields) {
        this.scrollToErrors()
        return
      }

      const formData = {
        ...this.formData
      }

      const formatFormData = {
        name: formData.name,
        greetBonus: parseInt(formData.greetBonus),
        greetBonusDays: formData.greetBonusDays === 'Сразу' ? 1 : 0,
        greetBonusDaysCanSpend: parseInt(formData.greetBonusDaysCanSpend),
        greetBonusBurn: formData.greetBonusBurn === 'Да' ? 1 : 0,
        greetBonusBurnDays: parseInt(formData.greetBonusBurnDays),
        birthdayBonus: parseInt(formData.birthdayBonus),
        birthdayBonusDays: parseInt(formData.birthdayBonusDays),
        birthdayBonusBurn: formData.birthdayBonusBurn === 'Да' ? 1 : 0,
        birthdayBonusBurnDays: parseInt(formData.birthdayBonusBurnDays),
        noShoppingBonus: parseInt(formData.noShoppingBonus),
        noShoppingBonusDays: parseInt(formData.noShoppingBonusDays),
        noShoppingBonusBurn: formData.noShoppingBonusBurn === 'Да' ? 1 : 0,
        noShoppingBonusBurnDays: parseInt(formData.noShoppingBonusBurnDays),
        bonusAfterBuy: formData.bonusAfterBuy === 'Сразу' ? 1 : 0,
        bonusAfterBuyHours: parseInt(formData.bonusAfterBuyHours),
        percentCanUse: parseFloat(formData.percentCanUse),
        usePhone: formData.usePhone === 'Да' ? 1 : 0,
        bonusBurn: formData.bonusBurn === 'Да' ? 1 : 0,
        bonusBurnDays: parseInt(formData.bonusBurnDays),
        bonusSendFor: parseInt(formData.bonusSendFor),
        specialBonusProgram: formData.specialBonusProgram,
        clientLostDays: parseInt(formData.clientLostDays),
        suspicionPurchaseDay: parseInt(formData.suspicionPurchaseDay),
        suspicionPurchaseWeek: parseInt(formData.suspicionPurchaseWeek),
        suspicionPurchaseMonth: parseInt(formData.suspicionPurchaseMonth),
        suspicionRecommendDay: parseInt(formData.suspicionRecommendDay),
        suspicionRecommendWeek: parseInt(formData.suspicionRecommendWeek),
        suspicionRecommendMonth: parseInt(formData.suspicionRecommendMonth),
        statuses: this.bonusesForRequest
      }

      fetch(window.host + 'users/activities/' + this.formData.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify(formatFormData)
      }).then(async response => {
        const data = await response.json()

        console.log(data.data.isNotificationsChanged)

        if (data.data.isNotificationsChanged) {
          this.popUpMessages = true
        }

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.$store.commit('animateStore/animateSave')
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: false
          }
        )
      }).catch(err => {
        this.formError = err
        this.$store.commit('techStore/checkError', { err })
      })

      if (this.formData.noShoppingBonus > 0 && this.formData.noShoppingBonusBurn === 'Нет') {
        if (!localStorage.getItem('bonus_burn_warning')) this.bonusesPopup = true
      }
    }
  },
  created () {
    fetch(window.host + 'users/activities', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      const activityData = data.data

      const percentCanUseSplit = activityData.percentCanUse ? activityData.percentCanUse.toString().split('.') : ['']
      if (percentCanUseSplit.length === 2 && percentCanUseSplit[1].length === 1) {
        percentCanUseSplit[1] += '0'
      }
      const collectPercentCanUseSplit = percentCanUseSplit.join('.')
      this.formData = Object.assign(this.formData, {
        id: activityData.id,
        name: activityData.name,
        greetBonus: activityData.greetBonus,
        greetBonusBurnDays: activityData.greetBonusBurnDays || '',
        birthdayBonus: activityData.birthdayBonus,
        birthdayBonusDays: activityData.birthdayBonusDays,
        birthdayBonusBurnDays: activityData.birthdayBonusBurnDays || '',
        noShoppingBonus: activityData.noShoppingBonus,
        noShoppingBonusDays: activityData.noShoppingBonusDays,
        noShoppingBonusBurnDays: activityData.noShoppingBonusBurnDays || '',
        bonusAfterBuyHours: activityData.bonusAfterBuyHours || '',
        percentCanUse: collectPercentCanUseSplit ? `${collectPercentCanUseSplit}%` : '',
        bonusBurnDays: activityData.bonusBurnDays || '',
        bonusSendFor: activityData.bonusSendFor || '',
        specialBonusProgram: activityData.specialBonusProgram || '',
        clientLostDays: activityData.clientLostDays,
        suspicionPurchaseDay: activityData.suspicionPurchaseDay,
        suspicionPurchaseWeek: activityData.suspicionPurchaseWeek,
        suspicionPurchaseMonth: activityData.suspicionPurchaseMonth,
        suspicionRecommendDay: activityData.suspicionRecommendDay,
        suspicionRecommendWeek: activityData.suspicionRecommendWeek,
        suspicionRecommendMonth: activityData.suspicionRecommendMonth,
        greetBonusBurn: activityData.greetBonusBurn === 1 ? 'Да' : 'Нет',
        birthdayBonusBurn: activityData.birthdayBonusBurn === 1 ? 'Да' : 'Нет',
        noShoppingBonusBurn: activityData.noShoppingBonusBurn === 1 ? 'Да' : 'Нет',
        usePhone: activityData.usePhone === 1 ? 'Да' : 'Нет',
        bonusBurn: activityData.bonusBurn === 1 ? 'Да' : 'Нет',
        greetBonusDays: activityData.greetBonusDays === 1 ? 'Сразу' : 'Не сразу',
        greetBonusDaysCanSpend: activityData.greetBonusDaysCanSpend || '',
        bonusAfterBuy: activityData.bonusAfterBuy === 1 ? 'Сразу' : 'Не сразу',
        referralLevel1: parseInt(activityData.referralLevel1),
        referralLevel2: parseInt(activityData.referralLevel2),
        referralLevel3: parseInt(activityData.referralLevel3)
      })
      setTimeout(() => {
        this.isFormLoaded = true
      }, 500)

      if (activityData.statuses.length) {
        let smallestId = activityData.statuses[0].id
        this.statusesData.data = activityData.statuses.map(el => {
          if (el.id < smallestId) {
            smallestId = el.id
          }

          const bonusSplit = el.bonus ? el.bonus.toString().split('.') : ['']
          if (bonusSplit.length === 2 && bonusSplit[1].length === 1) {
            bonusSplit[1] += '0'
          }
          const collectBonusSplit = bonusSplit.join('.')

          return {
            id: el.id,
            bonusesName: el.name,
            bonusRate: collectBonusSplit ? `${collectBonusSplit}%` : '─',
            bonusSum: el.sum ? `${el.sum}р.` : '─',
            bonusRefferalNumber: el.recommendCount || '─'
          }
        })
      }

      const referralLevel1Split = activityData.referralLevel1 ? activityData.referralLevel1.toString().split('.') : ['']
      if (referralLevel1Split.length === 2 && referralLevel1Split[1].length === 1) {
        referralLevel1Split[1] += '0'
      }
      const collectReferralLevel1Split = referralLevel1Split.join('.')

      const referralLevel2Split = activityData.referralLevel2 ? activityData.referralLevel2.toString().split('.') : ['']
      if (referralLevel2Split.length === 2 && referralLevel2Split[1].length === 1) {
        referralLevel2Split[1] += '0'
      }
      const collectReferralLevel2Split = referralLevel2Split.join('.')

      const referralLevel3Split = activityData.referralLevel3 ? activityData.referralLevel3.toString().split('.') : ['']
      if (referralLevel3Split.length === 2 && referralLevel3Split[1].length === 1) {
        referralLevel3Split[1] += '0'
      }
      const collectReferralLevel3Split = referralLevel3Split.join('.')

      this.referralBonusSum =
        (collectReferralLevel1Split ? parseInt(collectReferralLevel1Split) : 0) +
        (collectReferralLevel2Split ? parseInt(collectReferralLevel2Split) : 0) +
        (collectReferralLevel3Split ? parseInt(collectReferralLevel3Split) : 0)
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./settingsBonuses.scss" scoped/>
<style lang="scss" src="./../../components/textInput/textInput.scss" scoped/>
