<template>
  <div class="clients-header">
    <div class="clients-filter">
      <xFilter
        :rowCount="rowCount"
        @onExcelExport="onExcelExport"
        @apply="requestClients"
        @reset="resetValues"
      >
        <xFilterItem
          v-for="item in tags"
          :key="item.id"
          :inputNumber="3"
          :item="item"
          @logChange="filtering"/>
      </xFilter>
    </div>
    <div class="clients-search">
      <textInput
        placeholder="Введите фамилию клиента или номер телефона..."
        autocomplete="off"
        @onChange="searchString"
      >
        <template v-slot:before>
          <button class="search-button">
            <icon class="search-icon" name="search"/>
          </button>
        </template>
      </textInput>
    </div>
  </div>
  <tags :tags="tags" :tagsCount="tagsCount" @deleteTag="deletingTag"/>
  <xTable
    v-if="tableData.data.length"
    v-bind="tableData"
    @onSort="sortAction"
    @onPageChange="requestClientsByPage"
  >
    <template v-slot="{ records }" >
      <td>
        <button @click="setMoreActionPopupData(records.id)" class="msg-table-edit">
          <span class="link">Подробнее</span>
        </button>
      </td>
    </template>
  </xTable>
  <div v-else class="empty-list">
    Список операций пока пуст
  </div>
  <pop-up :open="popUpMore" @close="popUpMore = !popUpMore" sizer="pop-verysmall">
    <div class="x-pop-desc-black">
      {{ popUpMoreData.date }}
    </div>
    <div class="more-keyinfo">
      <h2 class="x-pop-title">
        Операция {{ popUpMoreData.number }}
      </h2>
      <p v-if="popUpMoreData.refund_id" class="x-pop-desc">
        Возврат по <span class="return red" @click="setMoreActionPopupDataFromRequest(popUpMoreData.refund_id)">Операции {{ popUpMoreData.number }}</span>
      </p>
    </div>
    <div class="x-pop-spacer"></div>
    <div class="x-pop-item">
      <div class="x-pop-item__desc">
        {{ popUpMoreData.refund_id ? 'Сумма возврата' : 'Сумма покупки' }}
      </div>
      <div v-if="!popUpMoreData.refund_id" class="x-pop-item__text">
        {{ getMoreDataSum }} RUB
      </div>
      <p class="x-pop-desc">
        <span :class="popUpMoreData.refund_id && 'red'">{{ getMoreDataBonuses }}</span> - бонусами
        <br>
        <span :class="popUpMoreData.refund_id && 'red'">{{ getMoreDataRub }}</span> - деньгами
      </p>
    </div>
    <div v-if="popUpMoreData.refunds.length" class="x-pop-spacer"></div>
    <div v-if="popUpMoreData.refunds.length" class="x-pop-item">
      <div class="x-pop-item__desc">
        Возврат по операции
      </div>
      <p v-for="refund in popUpMoreData.refunds"
         :key="refund.id"
         class="x-pop-desc"
      >
        <span>{{ refund.created_at }}</span>
        <br>
        <span class="red">{{ getFormattedBonuses(refund.bonuses) }}</span> - бонусами,
        <span class="red">{{ getFormattedRub(refund.roubles) }}</span> - деньгами
        <span class="return" @click="setMoreActionPopupDataFromRequest(refund.id)">Детали</span>
      </p>
    </div>
    <div class="x-pop-spacer"></div>
    <div class="x-pop-item">
      <div class="x-pop-item__desc">
        Клиент
      </div>
      <router-link :to="'/admin/customer/' + popUpMoreData.clientId" class="x-pop-item__text link">
        {{ popUpMoreData.clientName }}
      </router-link>
    </div>
    <div class="x-pop-item">
      <div class="x-pop-item__desc">
        Кассир
      </div>
      <router-link :to="'/admin/team/' + popUpMoreData.cashierId" class="x-pop-item__text link">
        {{ popUpMoreData.cashierName }}
      </router-link>
    </div>
    <div class="x-pop-footer">
      <xButton
        v-if="!(popUpMoreData.sum_refunds >= popUpMoreData.sum || popUpMoreData.refund_id)"
        @click="goRefund"
      >
        Возврат по операции
      </xButton>
      <button
        v-if="!popUpMoreData.refund_id"
        @click="deleteMoreAction"
        class="link x-pop-back"
        :class="(popUpMoreData.sum_refunds >= popUpMoreData.sum || popUpMoreData.refund_id) && 'ml-auto'"
      >
        Удалить
      </button>

      <xButton v-if="popUpMoreData.refund_id" @click="popUpMore = !popUpMore">Закрыть</xButton>
    </div>
  </pop-up>
  <transition name="RefundPop">
    <pop-up :open="popUpRefund" @close="popUpRefund = !popUpRefund">
      <div class="refund-pop">
        <h2 class="x-pop-title">
          Возврат по операции
        </h2>
        <div class="refund-info">
          <div class="refund-item">
            <div class="x-pop-desc">Сумма покупки</div>
            <text-input :default-value="getMoreDataSum" :disabled="true" />
          </div>
          <div class="refund-item">
            <div class="x-pop-desc">Деньги</div>
            <text-input :default-value="getMoreDataRub" :disabled="true" />
          </div>
          <div class="refund-item">
            <div class="x-pop-desc">Бонусы</div>
            <text-input :default-value="getMoreDataBonuses" :disabled="true" />
          </div>
        </div>
        <div class="x-pop-spacer"></div>
        <div class="x-pop-desc-black">
          Введите сумму, на которую нужно сделать возврат. <br> Деньги и бонусы к возврату будут рассчитаны пропорционально
        </div>
        <div class="x-pop-desc">Сумма к возврату</div>
        <div class="input-wrapper">
          <div class="input-container" >
            <numberFormat
              class="input"
              v-model:value="returnSum"
              :options="numberFormat"
              @blur="e => onChangeReturnSum(e.target.value)"
            ></numberFormat>
          </div>
        </div>
        <div class="x-pop-footer">
          <xButton @click="returnAction">Сделать возврат</xButton>
          <button @click="closeReturnAction" class="link x-pop-back">Отмена</button>
        </div>
      </div>
    </pop-up>
  </transition>
</template>

<script>
import debounce from 'lodash.debounce'
import xFilter from '@/components/xFilter/xFilter'
import textInput from '@/components/textInput/textInput'
import xTable from '@/components/xTable/xTable'
import popUp from '@/components/popUp'
import xButton from '@/components/xButton'
import tags from '@/components/tags'
import xFilterItem from '@/components/xFilterItem'
import numberFormat from '@/components/numberFormat'

const searchByString = debounce((callback) => {
  callback()
}, 1000)

export default {
  name: 'operations',
  components: {
    xFilter,
    textInput,
    xTable,
    popUp,
    xButton,
    tags,
    xFilterItem,
    numberFormat
  },
  data () {
    return {
      popUpMore: false,
      popUpRefund: false,
      returnSum: '',
      popUpMoreData: {
        id: 0,
        number: '0',
        date: '00.00.0000 00:00',
        sum: '0',
        rub: '0',
        bonuses: '0',
        currency: 'RUB',
        name: '',
        clientName: '',
        clientId: 3,
        cashierName: '',
        cashierId: 3,
        refund_id: 0,
        refunds: [],
        sum_refunds: 0
      },
      tags: [
        { name: 'Дата', type: 'date', code: 'created_at' },
        { name: 'Сумма покупки', type: 'number', code: 'sum' },
        { name: 'Деньги', type: 'number', code: 'roubles' },
        { name: 'Бонусы', type: 'number', code: 'bonuses' },
        { name: 'Уровень', type: 'checkbox', code: 'level', text1: '1', text2: '2', text3: '3' }
      ],
      rowCount: 0,
      tagsCount: 0,
      sort: {
        name: 'Дата / время',
        type: 'desc'
      },
      searchQuery: '',
      originalData: [],
      tableData: {
        dateTwoStroke: true,
        showToggler: true,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        pageSizeX: 1,
        data: [],
        wordChecker: true,
        optionsX: [
          { name: 'Дата / время', value: 'dateCalendar', id: 0 },
          { name: 'Клиент', value: 'client', id: 1 },
          { name: 'Уровень', value: 'level', id: 2 },
          { name: 'Филиал', value: 'branch', id: 3 },
          { name: 'Кассир', value: 'employee', id: 4 },
          { name: 'Сумма покупки', value: 'purchasesAmount', id: 5 },
          { name: 'Деньги', value: 'purchasesMoney', id: 6 },
          { name: 'Бонусы', value: 'purchasesBonuses', id: 7 }
        ],
        allNamesX: ['Дата / время', 'Клиент', 'Уровень', 'Филиал', 'Кассир', 'Сумма покупки', 'Деньги', 'Бонусы'],
        newNamesX: [],
        importantNamesX: ['Дата / время', 'Клиент', 'Сумма покупки'],
        defaultNamesX: ['Дата / время', 'Клиент', 'Кассир', 'Сумма покупки', 'Деньги', 'Бонусы'],
        namesForHeaderX: [],
        useSort: true,
        defaultSortName: 'Дата / время',
        defaultSort: 'desc'
      },
      numberFormat: {
        decimal: '',
        thousand: ' ',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      }
    }
  },
  computed: {
    getMoreDataSum () {
      return new Intl.NumberFormat('ru-RU').format(this.popUpMoreData.sum)
    },
    getMoreDataRub () {
      return new Intl.NumberFormat('ru-RU').format(this.popUpMoreData.rub)
    },
    getMoreDataBonuses () {
      return new Intl.NumberFormat('ru-RU').format(this.popUpMoreData.bonuses)
    },
    getFilters () {
      const filter = {}

      for (const i in this.tags) {
        const tag = this.tags[i]

        if (tag.code === 'level') {
          // eslint-disable-next-line no-case-declarations
          const tagValue = []

          if (tag.check1) {
            tagValue.push('1')
          }
          if (tag.check2) {
            tagValue.push('2')
          }
          if (tag.check3) {
            tagValue.push('3')
          }

          if (tagValue.length) {
            filter.level = tagValue
          }
        } else {
          if (tag.valueFrom) {
            filter[`${tag.code}_from`] = tag.valueFrom
          }

          if (tag.valueTo) {
            filter[`${tag.code}_to`] = tag.valueTo
          }
        }
      }

      const result = {
        searchQuery: this.searchQuery,
        filter
      }

      const sortField = this.tableData.optionsX.find(el => el.name === this.sort.name)
      if (sortField) {
        result.sort = {
          name: sortField.value,
          type: this.sort.type
        }
      }

      return result
    },
    getPreparedOnlyFilter () {
      const filters = this.getFilters

      const filterForRequest = []
      for (const key in filters.filter) {
        const val = filters.filter[key]
        if (Array.isArray(val)) {
          for (const innerVal of val) {
            filterForRequest.push(`${key}[]=${innerVal}`)
          }
        } else {
          filterForRequest.push(`${key}=${val}`)
        }
      }

      return filterForRequest
    },
    getPreparedFilterForRequest () {
      const filters = this.getFilters

      const filterForRequest = [...this.getPreparedOnlyFilter]

      const searchQuery = filters.searchQuery.trim()
      if (searchQuery.length) {
        filterForRequest.push(`searchQuery=${searchQuery}`)
      }

      const sort = filters.sort
      const sortMapNames = {
        dateCalendar: 'created_at',
        client: 'client',
        level: 'level',
        branch: 'office',
        employee: 'cashier',
        purchasesAmount: 'sum',
        purchasesMoney: 'roubles',
        purchasesBonuses: 'bonuses'
      }

      filterForRequest.push(`sort=${sortMapNames[sort.name]}`)
      filterForRequest.push(`order=${sort.type}`)

      return filterForRequest
    }
  },
  methods: {
    setMoreActionPopupDataFromRequest (id) {
      fetch(`${window.host}purchases/${id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.popUpMore = false
        setTimeout(() => {
          this.popUpMore = true
          const moreData = data.data

          this.popUpMoreData.id = moreData.id
          this.popUpMoreData.date = moreData.created_at
          this.popUpMoreData.number = moreData.number
          this.popUpMoreData.sum = moreData.sum
          this.popUpMoreData.rub = moreData.roubles
          this.popUpMoreData.bonuses = moreData.bonuses
          this.popUpMoreData.name = this.$store.state.techStore.companyName || ''
          this.popUpMoreData.clientName = moreData.client.name
          this.popUpMoreData.clientId = moreData.client.id
          this.popUpMoreData.cashierName = moreData.cashier ? moreData.cashier.name : '─'
          this.popUpMoreData.cashierId = moreData.cashier ? moreData.cashier.id : '─'
          this.popUpMoreData.refund_id = moreData.refund_id
          this.popUpMoreData.sum_refunds = moreData.sum_refunds
          this.popUpMoreData.refunds = moreData.refunds.map(el => ({
            id: el.id,
            created_at: el.created_at,
            bonuses: el.bonuses,
            roubles: el.roubles
          }))
        }, 500)
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    getFormattedRub (rub) {
      return new Intl.NumberFormat('ru-RU').format(rub)
    },
    getFormattedBonuses (bonuses) {
      return new Intl.NumberFormat('ru-RU').format(bonuses)
    },
    searchString (val) {
      this.searchQuery = val
      searchByString(this.requestClients.bind(this))
    },
    onChangeReturnSum (val) {
      const toInt = parseInt(val.replace(' ', ''))
      if (toInt > this.popUpMoreData.sum) {
        this.returnSum = this.popUpMoreData.sum
      }
    },
    closeReturnAction () {
      this.popUpRefund = false
      this.returnSum = ''
    },
    returnAction () {
      this.popUpRefund = false
      if (this.returnSum) {
        fetch(`${window.host}purchases/${this.popUpMoreData.id}/refund`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          },
          body: JSON.stringify({
            sum: this.returnSum
          })
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          this.requestClients()
          this.$store.commit('animateStore/animateSave')
          this.returnSum = ''
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }
    },
    deleteMoreAction () {
      this.popUpMore = false

      this.$store.commit(
        'popupStore/show',
        {
          title: 'Вы удаляете операцию',
          text: 'Подтвердите удаление',
          buttonText: 'Отмена',
          rollBackButtonText: 'Удалить',
          useFullWindow: false,
          callback: () => {},
          errorCallback: () => {
            fetch(`${window.host}purchases/${this.popUpMoreData.id}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('access_token')
              }
            }).then(async response => {
              const data = await response.json()

              if (!response.ok || !data.success) {
                const error = (data && data.message) || response.status
                return Promise.reject(error)
              }

              this.requestClients()
              this.$store.commit('animateStore/animateSave')
            }).catch(err => {
              this.$store.commit('techStore/checkError', { err })
            })
          }
        }
      )
    },
    setMoreActionPopupData (id) {
      const findedEl = this.originalData.find(el => el.id === id)

      if (findedEl) {
        this.popUpMore = true

        this.popUpMoreData.id = findedEl.id
        this.popUpMoreData.date = findedEl.created_at
        this.popUpMoreData.sum = findedEl.sum
        this.popUpMoreData.number = findedEl.number
        this.popUpMoreData.rub = findedEl.roubles
        this.popUpMoreData.bonuses = findedEl.bonuses
        this.popUpMoreData.name = this.$store.state.techStore.companyName || ''
        this.popUpMoreData.clientName = findedEl.client.name
        this.popUpMoreData.clientId = findedEl.client.id
        this.popUpMoreData.cashierName = findedEl.cashier ? findedEl.cashier.name : '─'
        this.popUpMoreData.cashierId = findedEl.cashier ? findedEl.cashier.id : '─'
        this.popUpMoreData.refund_id = findedEl.refund_id
        this.popUpMoreData.sum_refunds = findedEl.sum_refunds
        this.popUpMoreData.refunds = findedEl.refunds.map(el => ({
          id: el.id,
          created_at: el.created_at,
          bonuses: el.bonuses,
          roubles: el.roubles
        }))
      }
    },
    setClients (arrClients) {
      this.tableData.data = arrClients.map(el => {
        const isRefund = el.refund_id > 0
        const roubles = el.roubles ? `${isRefund ? '-' : ''}${el.roubles}` : '─'
        const sum = el.sum ? `${isRefund ? '-' : ''}${el.sum}` : '─'
        const bonuses = el.bonuses ? `${isRefund ? '-' : ''}${el.bonuses}` : '─'

        return {
          id: el.id || '─',
          dateCalendar: el.created_at || '─',
          client: el.client.name || '─',
          level: el.level || '─',
          branch: el.office.name || '─',
          employee: el.cashier.name || '─',
          purchasesAmount: sum,
          purchasesMoney: roubles,
          purchasesBonuses: bonuses
        }
      })
    },
    onExcelExport: function () {
      fetch(window.host + 'excel/code', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (!data.data) {
          throw Error('Нет авторизационного кода')
        }

        const preparedFilter = [...this.getPreparedOnlyFilter]
        preparedFilter.push('uid=' + data.data)
        const uri = window.host + 'excel/purchases?' + preparedFilter.join('&')

        const link = document.createElement('a')
        link.href = uri
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    requestClientsByPage: function (pageNumber) {
      const preparedFilter = [...this.getPreparedFilterForRequest]
      preparedFilter.push(`page=${pageNumber}`)

      fetch(window.host + 'purchases?' + preparedFilter.join('&'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.setClients(data.data.purchases)
        this.originalData = data.data.purchases

        this.tableData.totalPagesX = data.data.paginate.last_page
        this.tableData.pageSizeX = data.data.paginate.per_page
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    requestClients: function () {
      const preparedFilter = [...this.getPreparedFilterForRequest]

      fetch(window.host + 'purchases?' + preparedFilter.join('&'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.setClients(data.data.purchases)
        this.originalData = data.data.purchases

        this.tableData.totalPagesX = data.data.paginate.last_page
        this.tableData.pageSizeX = data.data.paginate.per_page
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    resetValues: function () {
      this.tagsCount = 0
      for (let i = 0; i < this.tags.length; i++) {
        this.tags[i].valueTo = ''
        this.tags[i].valueFrom = ''
        this.tags[i].output = ''
        this.tags[i].output1 = ''
        this.tags[i].output2 = ''
        this.tags[i].output3 = ''
        this.tags[i].check1 = false
        this.tags[i].check2 = false
        this.tags[i].check3 = false
      }

      this.requestClients()
    },
    requestRowCount () {
      const preparedFilter = [...this.getPreparedFilterForRequest]
      preparedFilter.push('prefilter=y')

      fetch(window.host + 'purchases?' + preparedFilter.join('&'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.rowCount = data.data?.count || 0
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    sortAction (obj) {
      this.sort.name = obj.name
      this.sort.type = obj.type

      this.requestClients()
    },
    deletingTag (name, direct) {
      this.tagsCount -= 1
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].name === name) {
          this.tags[i].output = ''

          if (name === 'Уровень') {
            if (direct === 'check1') {
              this.tags[i].check1 = false
              this.tags[i].output1 = ''
            }
            if (direct === 'check2') {
              this.tags[i].check2 = false
              this.tags[i].output2 = ''
            }
            if (direct === 'check3') {
              this.tags[i].check3 = false
              this.tags[i].output3 = ''
            }
          } else {
            this.tags[i].valueTo = ''
            this.tags[i].valueFrom = ''
          }
        }
      }

      this.requestClients()
    },
    filtering (value, name, direct) {
      if (value === '') {
        this.deletingTag(name)
      } else if (!value) {
        this.deletingTag(name, direct)
      } else {
        this.tagsCount += 1

        if (name === 'Уровень') {
          const output1 = 'Уровень: 1'
          const output2 = 'Уровень: 2'
          const output3 = 'Уровень: 3'
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].name === name) {
              if (direct === 'check1') {
                this.tags[i][direct] = true
                this.tags[i].output1 = output1
              }
              if (direct === 'check2') {
                this.tags[i][direct] = true
                this.tags[i].output2 = output2
              }
              if (direct === 'check3') {
                this.tags[i][direct] = true
                this.tags[i].output3 = output3
              }
            }
          }
        } else {
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].name === name) {
              const fromVal = direct === 'from' ? value : (this.tags[i].valueFrom ? this.tags[i].valueFrom : '')
              const toVal = direct === 'to' ? value : (this.tags[i].valueTo ? this.tags[i].valueTo : '')

              this.tags[i].valueFrom = fromVal
              this.tags[i].valueTo = toVal

              const prefixFrom = fromVal ? 'от' : ''
              const prefixTo = toVal ? 'до' : ''

              const output = `${name}: ${prefixFrom} ${fromVal} ${prefixTo} ${toVal}`
              this.tags[i].output = output.replace('  ', ' ')
            }
          }
        }
      }

      this.requestRowCount()
    },
    goRefund: function () {
      this.popUpMore = false
      this.popUpRefund = true
    }
  },
  emits: ['titleName'],
  created () {
    this.$emit('titleName', 'Операции')
    this.requestClients()
    this.requestRowCount()
  }
}
</script>

<style lang="scss" src="./operations.scss" scoped/>
<style lang="scss" src="./../../components/textInput/textInput.scss" scoped/>
