<template>
  <accountContent title="Добро пожаловать">
    <div class="input-container">
      <form>
        <div v-if="!!formError" class="error">
          {{ formError }}
        </div>

        <div class="x-input-item">
          <div class="input-name">Логин</div>
          <div class="input-content" :class="emailErrorText && 'error-wrap'">
            <textInput
              placeholder="example@mail.ru"
              autocomplete="mail username"
              v-bind:defaultValue="email"
              @onChange="onChangeEmail"
            />
          </div>
        </div>
        <div v-if="!!emailErrorText" class="error error-input">
          {{ emailErrorText }}
        </div>
        <div class="x-input-item">
          <div class="input-name">Пароль</div>
          <div class="input-content" :class="passwordErrorText && 'error-wrap'">
            <textInput
              autocomplete="new-password"
              :type="isPasswordShow ? 'text' : 'password'"
              placeholder="••••••••••••••••"
              v-bind:defaultValue="password"
              @onChange="onChangePassword"
            >
              <template #after>
                <eye :onClick="showPassword"/>
              </template>
            </textInput>
          </div>
        </div>
        <div class="forgot-left">
          <router-link class="link" to="/restore">
            Забыли пароль?
          </router-link>
        </div>
        <div v-if="!!passwordErrorText" class="error error-input">
          {{ passwordErrorText }}
        </div>
      </form>
    </div>
    <div class="buttons">
      <div class="reg-button">
        <xButton @click="onSubmit">
          Вход
        </xButton>
      </div>
      <router-link to="/create" class="link button-link">Регистрация нового аккаунта</router-link>
    </div>
  </accountContent>
</template>

<script>
import accountContent from '@/components/accountContent'
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'
import eye from '@/components/eye'

const EMAIL_ERROR = 'Укажите корректный e-mail'
const PASSWORD_ERROR = 'Укажите пароль'

export default {
  name: 'accountNew',
  components: {
    accountContent,
    textInput,
    xButton,
    eye
  },
  data () {
    return {
      isPasswordShow: false,
      email: '',
      emailErrorText: '',
      password: '',
      passwordErrorText: '',
      formError: ''
    }
  },
  methods: {
    showPassword () {
      this.isPasswordShow = !this.isPasswordShow
    },
    onChangeEmail (email) {
      this.email = email
      // eslint-disable-next-line no-useless-escape
      if (email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        this.emailErrorText = ''
      }
    },
    onChangePassword (password) {
      const pass = password.trim()
      this.password = pass
      if (pass) {
        this.passwordErrorText = ''
      }
    },
    validateForm () {
      // eslint-disable-next-line no-useless-escape
      if (!this.email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        this.emailErrorText = EMAIL_ERROR
      }

      if (!this.password) {
        this.passwordErrorText = PASSWORD_ERROR
      }
    },
    async onSubmit () {
      this.validateForm()

      if (!this.emailErrorText && !this.passwordErrorText) {
        // login: A1fghlkjghfljkojghfkl@sdfsdf.sdf
        // password: A1fghlkjghfljkojghfkl@sdfsdf.sdf
        const url = window.domen + '/api/oauth/token'
        const code = {
          grant_type: 'password',
          client_id: '4',
          client_secret: 'UB2BeuYwwaD9U8aM5PTwUfabvvECsL24mj7TqQmV',
          username: this.email,
          password: this.password
        }

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(code)
        }).then(async response => {
          const data = await response.json()

          if (!response.ok) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          // eslint-disable-next-line
          const { access_token, refresh_token } = data
          localStorage.setItem('access_token', access_token)
          localStorage.setItem('refresh_token', refresh_token)

          this.$store.commit('techStore/doAuth')
          this.$router.push('/admin/clients')
        }).catch(err => {
          this.formError = err
          this.$store.commit('techStore/checkError', { err })
        })
      }
    }
  },
  created () {
    this.$store.commit('techStore/checkLogin', { path: 'admin' })
  }
}
</script>

<style lang="scss" src="./accountLogin.scss" scoped />
