<template>
  <div class="customer-container">
    <div class="customer-header">
      <div class="header-item d-g">
        <div>
          <p class="d-i mr-10">{{ this.userData.name }}</p>
          <a v-if="userData.phone" :href="'tel: ' + userData.phone" class="phone d-i" style="display: block!important;">
            <icon name="phone"/>
            {{ userData.phone }}
          </a>
        </div>
        <div v-if="userData.gender">
          {{ userData.gender }}
        </div>
      </div>
      <p v-if="userData.birthday" class="header-item">
        {{ clientAge }}
        <br>
        Дата рождения: {{ userData.birthday }}
      </p>
      <p class="header-item">
        Статус: {{ userData.status }}
      </p>
      <div class="header-item header-icon-container">
        <button @click="popUpDelete = true" class="header-icon">
          <icon class="minus" name="minus"/>
        </button>
        <button @click="popUpAdd = true" class="header-icon">
          <icon class="plus" name="plus"/>
        </button>
        <button @click="popUpEdit = true" class="header-icon">
          <icon class="crown" name="crown"/>
        </button>
        <button @click="popUpBlock = true" class="header-icon">
          <icon v-if="userData.active" class="lock" name="lock"/>
          <icon v-else class="unlock" name="unlock"/>
        </button>
      </div>
    </div>
    <div class="customer-body">
      <div class="columns-4">
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Остаток бонусов</div>
          <div class="columns-4-item-info">{{ formatSum(userData.bonus_number, true) }}</div>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Покупки (шт. / руб.)</div>
          <div class="columns-4-item-info">
            {{ userData.purchase.purchase_count }} / {{ formatSum(userData.purchase.purchase_sum) }}
          </div>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Оплачено (руб.)</div>
          <div class="columns-4-item-info">{{ formatSum(userData.purchase.pay_roubles) }}</div>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">Оплачено (бонусы)</div>
          <div class="customer-item-info">{{ formatSum(userData.purchase.pay_bonuses) }}</div>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">
            <span class="note-wrapper">
              Группа <br> по давности
              <note note-position="bottom-to-right" icon-size="note-big">
              <b>Ваши клиенты разделены на 3 равные группы по суммам покупок:</b>
              <br><br>
              Макс – клиент входит в первую треть ваших клиентов по суммам покупок;
              <br><br>
              Сред – клиент входит во вторую треть ваших клиентов по суммам покупок;
              <br><br>
              Мин – клиент входит в третью треть ваших клиентов по суммам покупок
            </note>
            </span>
          </div>
          <div class="customer-item-info">{{ userData.antiquity }}</div>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">
            <span class="note-wrapper">
              Группа <br> по частоте
              <note note-position="bottom-to-right" icon-size="note-big">
              <b>Ваши клиенты разделены на 3 равные группы по суммам покупок:</b>
              <br><br>
              Макс – клиент входит в первую треть ваших клиентов по суммам покупок;
              <br><br>
              Сред – клиент входит во вторую треть ваших клиентов по суммам покупок;
              <br><br>
              Мин – клиент входит в третью треть ваших клиентов по суммам покупок
            </note>
            </span>
          </div>
          <div class="columns-4-item-info">{{ userData.frequency }}</div>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">
            <span class="note-wrapper">
              Группа <br> по среднему чеку
              <note icon-size="note-big">
              <b>Ваши клиенты разделены на 3 равные группы по суммам покупок:</b>
              <br><br>
              Макс – клиент входит в первую треть ваших клиентов по суммам покупок;
              <br><br>
              Сред – клиент входит во вторую треть ваших клиентов по суммам покупок;
              <br><br>
              Мин – клиент входит в третью треть ваших клиентов по суммам покупок
            </note>
            </span>
          </div>
          <div class="columns-4-item-info">{{ userData.average_check }}</div>
        </div>
        <div class="columns-4-item">
          <div class="columns-4-item-desc">
            <span class="note-wrapper">
              Группа <br> по сумме покупок
              <note icon-size="note-big">
              <b>Ваши клиенты разделены на 3 равные группы по суммам покупок:</b>
              <br><br>
              Макс – клиент входит в первую треть ваших клиентов по суммам покупок;
              <br><br>
              Сред – клиент входит во вторую треть ваших клиентов по суммам покупок;
              <br><br>
              Мин – клиент входит в третью треть ваших клиентов по суммам покупок
            </note>
            </span>
          </div>
          <div class="columns-4-item-info">{{ userData.sum_group }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-footer">
    <div v-if="userData.recommend_from.id" class="customer-recommendation">
      По рекомендации пользователя:
      <a class="router-link-active link" :href="'/admin/customer/' + userData.recommend_from.id">
        {{ userData.recommend_from.name }}
      </a>
    </div>
    <div class="customer-level">
      <div class="customer-level-block">
        <span class="customer-level-text">Рекомендации по уровням</span>
        <div class="customer-level-container">
        <div class="customer-level-item">
          <div class="customer-level-status">1 ур.</div>
          <div class="customer-level-number">
            <a v-if="userData.recommends.level1" :href="recommendsLevelUrl + '1'" class="link">{{ userData.recommends.level1 }}</a>
            <p v-else class="disabled-link">{{ userData.recommends.level1 }}</p>
          </div>
        </div>
        <div class="customer-level-item">
          <div class="customer-level-status">2 ур.</div>
          <div class="customer-level-number">
            <a v-if="userData.recommends.level2" :href="recommendsLevelUrl + '2'" class="link">{{ userData.recommends.level2 }}</a>
            <p v-else class="disabled-link">{{ userData.recommends.level2 }}</p>
          </div>
        </div>
        <div class="customer-level-item">
          <div class="customer-level-status">3 ур.</div>
          <div class="customer-level-number">
            <a v-if="userData.recommends.level3" :href="recommendsLevelUrl + '3'" class="link">{{ userData.recommends.level3 }}</a>
            <p v-else class="disabled-link">{{ userData.recommends.level3 }}</p>
          </div>
        </div>
      </div>
      </div>
      <div class="test-checkbox">
        <checkboxInput
          :name="'Отправлять тестовые сообщения'"
          :fontRegular="true"
          :checked="userData.test"
          @change="isChecked"
        />
      </div>
    </div>
  </div>
  <pop-up :open="popUpDelete" @close="closeDeleteBonuses" sizer="pop-small">
      <h2 class="x-pop-title">
        Списание бонусов
      </h2>
      <p class="x-pop-desc__orange">
        Клиент будет проинформирован о списании бонусов, но он не увидит приведенный ниже комментарий
      </p>
      <div class="pop-desc">Клиент</div>
      <div class="x-pop-desc-black">
        {{ userData.name }}, {{ bonusNumberFormat }}
      </div>

      <div class="pop-textoid">
        <div class="x-pop-desc">Количество бонусов к списанию</div>
        <div class="input-wrapper">
          <div class="input-container">
            <numberFormat
              class="input"
              type="text"
              v-model:value="popupBonusesData.bonuses"
              :options="formatter"
              :max="userData.bonus_number"
            ></numberFormat>
          </div>
        </div>
      </div>

      <div class="pop-textoid">
        <div class="x-pop-desc">Комментарий</div>
        <text-input :textarea="true" placeholder="Введите комментарий" :default-value="popupBonusesData.comment" @onChange="val => setPopupBonusesData(val, 'comment')"/>
      </div>

      <div class="pop-textoid">
        <div class="x-pop-desc">Пароль администратора</div>
        <text-input type="password" :default-value="popupBonusesData.password" @onChange="val => setPopupBonusesData(val, 'password')"/>
      </div>

      <div class="x-pop-footer">
        <xButton @click="deleteBonuses">Списать</xButton>
        <button @click="closeDeleteBonuses" class="link x-pop-back">Отмена</button>
      </div>
  </pop-up>
  <pop-up :open="popUpAdd" @close="closeAddBonuses" sizer="pop-small">
    <h2 class="x-pop-title">
      Начисление бонусов
    </h2>
    <p class="x-pop-desc__orange">
      Клиент будет проинформирован о начислении бонусов, но он не увидит приведенный ниже комментарий    </p>
    <div class="pop-desc">Клиент</div>
    <div class="x-pop-desc-black">{{ userData.name }}, {{ bonusNumberFormat }}</div>

    <div class="pop-textoid">
      <div class="x-pop-desc">Количество бонусов к начислению</div>
      <div class="input-wrapper">
        <div class="input-container">
          <numberFormat
            class="input"
            type="text"
            v-model:value="popupBonusesData.bonuses"
            :options="formatter"
          ></numberFormat>
        </div>
      </div>
    </div>

    <div class="pop-textoid">
      <div class="x-pop-desc">Комментарий</div>
      <text-input :textarea="true" placeholder="Введите комментарий" :default-value="popupBonusesData.comment" @onChange="val => setPopupBonusesData(val, 'comment')"/>
    </div>

    <div class="pop-textoid">
      <div class="x-pop-desc">Пароль администратора</div>
      <text-input type="password" :default-value="popupBonusesData.password" @onChange="val => setPopupBonusesData(val, 'password')"/>
    </div>

    <div class="x-pop-footer">
      <xButton @click="addBonuses">Начислить</xButton>
      <button @click="closeAddBonuses" class="link x-pop-back">Отмена</button>
    </div>
  </pop-up>
  <pop-up :open="popUpEdit" @close="popUpEdit = !popUpEdit" sizer="pop-small" additionalClass="pc-h-320">
    <h2 class="x-pop-title">
      Изменение статуса
    </h2>
    <div class="pop-textoid mt-40">
      <Multiselect
        v-model="statusValue"
        :options="statusNames"
        placeholder="Выберите статус"
        :canClear="false"
      />
      <div class="x-pop-desc mt-25">
        Клиент будет переведен со статуса <span>“{{ userData.status }}”</span> на новый статус
      </div>
    </div>

    <div class="x-pop-footer mt-40">
      <xButton @click="changeStatus">Изменить</xButton>
      <button @click="popUpEdit = false" class="link x-pop-back">Отмена</button>
    </div>
  </pop-up>
  <pop-up :open="popUpBlock" @close="closeBlockUser" sizer="pop-small">
    <h2 class="x-pop-title">
      {{ userData.active ? 'Блокировка' : 'Разблокировка' }}
    </h2>
    <p class="x-pop-desc__orange">
      {{ userData.active ? 'Клиент не сможет совершать операции и не будет учавствовать в рейтингах' : 'Клиент сможет совершать операции и будет учавствовать в рейтингах' }}
      <br><br>
      {{ userData.active ? 'Клиент НЕ ПОЛУЧИТ уведомление о блокировке' : 'Клиент НЕ ПОЛУЧИТ уведомление о разблокировке' }}
    </p>

    <div class="pop-textoid">
      <div class="x-pop-desc">Комментарий для кассира</div>
      <text-input :default-value="blockText" :textarea="true" placeholder="Введите комментарий" @onChange="setBlockText"/>
    </div>

    <div class="x-pop-footer">
      <xButton @click="blockUser">{{ userData.active ? 'Заблокировать' : 'Разблокировать' }}</xButton>
      <button @click="closeBlockUser" class="link x-pop-back">Отмена</button>
    </div>
  </pop-up>
</template>

<script>
import note from '@/components/note'
import popUp from '@/components/popUp'
import xButton from '@/components/xButton'
import TextInput from '@/components/textInput/textInput'
import textInput from '@/components/textInput'
import numberFormat from '@/components/numberFormat'
import CheckboxInput from '@/components/checkboxInput/checkboxInput'

const pluralize = (count, words, formattedCount = false) => {
  const countN = parseInt(count)
  const cases = [2, 0, 1, 1, 1, 2]
  const printCount = formattedCount || count.toString()
  return printCount + ' ' + words[(countN % 100 > 4 && countN % 100 < 20) ? 2 : cases[Math.min(countN % 10, 5)]]
}

export default {
  name: 'customerInformation',
  components: {
    TextInput,
    note,
    popUp,
    xButton,
    textInput,
    numberFormat,
    CheckboxInput
  },
  data () {
    return {
      formatter: {
        decimal: '',
        thousand: ' ',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },
      popupBonusesData: {
        bonuses: 0,
        comment: '',
        password: ''
      },
      popUpDelete: false,
      popUpAdd: false,
      popUpEdit: false,
      statusValue: null,
      popUpBlock: false,
      blockText: '',
      originalStatuses: [],
      userData: {
        active: true,
        status: '',
        name: '',
        gender: 'Мужской',
        phone: '',
        birthday: '',
        antiquity: '─',
        frequency: '─',
        average_check: '─',
        sum_group: '─',
        bonus_number: 0,
        referral_client_id: '',
        recommend_from: {
          id: 0,
          name: ''
        },
        recommends: { level1: 0, level2: 0, level3: 0 },
        purchase: { purchase_count: 0, purchase_sum: 0, pay_roubles: 0, pay_bonuses: 0 },
        test: false
      }
    }
  },
  watch: {
    'popupBonusesData.bonuses' (newVal) {
      if (this.popUpDelete) {
        if (newVal > this.userData.bonus_number) {
          this.popupBonusesData.bonuses = this.userData.bonus_number
        }
      }
    }
  },
  computed: {
    clientAge () {
      if (this.userData.birthday) {
        const currentDate = new Date()
        const birthDate = this.userData.birthday.split('.')
        const correctMonth = (currentDate.getMonth() + 1) < parseInt(birthDate[1]) ? 1 : 0
        const correctDate = (currentDate.getMonth() + 1) === parseInt(birthDate[1]) && currentDate.getDate() < parseInt(birthDate[0]) ? 1 : 0
        return pluralize(
          currentDate.getFullYear() - birthDate[2] - correctMonth - correctDate,
          ['год', 'года', 'лет']
        )
      }
      return false
    },
    recommendsLevelUrl () {
      return '/admin/customer/' + this.getUserId() + '/recommendations/?level='
    },
    bonusNumberFormat () {
      return pluralize(
        this.userData.bonus_number,
        ['бонус', 'бонуса', 'бонусов'],
        this.formatSum(this.userData.bonus_number, true)
      )
    },
    statusNames () {
      return this.originalStatuses.map(el => el.name)
    },
    selectedStatusId () {
      if (this.statusValue) {
        const findStatus = this.originalStatuses.find(el => el.name === this.statusValue)
        if (findStatus) {
          return findStatus.id
        }
      }

      return false
    }
  },
  methods: {
    isChecked (e) {
      const id = this.getUserId()

      fetch(`${window.host}clients/${id}/test/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify({
          test: e.target.checked
        })
      }).then(async response => {
        const data = await response.json()

        if (!data.success) {
          this.$store.commit('popupStore/show', { text: data.message, useFullWindow: false })
          return
        }

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.$store.commit('animateStore/animateSave')
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    deleteBonuses () {
      const id = this.getUserId()
      fetch(`${window.host}clients/${id}/bonuses/minus/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify(this.popupBonusesData)
      }).then(async response => {
        const data = await response.json()

        if (!data.success && data.message === 'Неверный пароль администратора') {
          this.$store.commit('popupStore/show', { text: data.message, useFullWindow: false })
          return
        }

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.$store.commit('animateStore/animateSave')
        this.userData.bonus_number = this.userData.bonus_number - this.popupBonusesData.bonuses
        this.closeDeleteBonuses()
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    closeDeleteBonuses () {
      this.popUpDelete = false

      this.popupBonusesData.bonuses = ''
      this.popupBonusesData.comment = ''
      this.popupBonusesData.password = ''
    },
    addBonuses () {
      this.popUpAdd = false

      const id = this.getUserId()
      fetch(`${window.host}clients/${id}/bonuses/plus/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify(this.popupBonusesData)
      }).then(async response => {
        const data = await response.json()

        if (!data.success && data.message === 'Неверный пароль администратора') {
          this.$store.commit(
            'popupStore/show',
            {
              text: data.message,
              useFullWindow: false,
              callback: () => {
                this.popUpAdd = true
              }
            })
          return
        }

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.$store.commit('animateStore/animateSave')
        this.userData.bonus_number = this.userData.bonus_number + this.popupBonusesData.bonuses
        this.closeAddBonuses()
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    closeAddBonuses () {
      this.popUpAdd = false

      this.popupBonusesData.bonuses = ''
      this.popupBonusesData.comment = ''
      this.popupBonusesData.password = ''
    },
    setPopupBonusesData (val, type) {
      this.popupBonusesData[type] = val
    },
    blockUser () {
      this.popUpBlock = false

      const isActive = this.userData.active
      const data = { comment: this.blockText }
      if (!isActive) {
        data.active = true
      }

      const id = this.getUserId()
      fetch(`${window.host}clients/${id}`, {
        method: isActive ? 'DELETE' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.userData.active = !this.userData.active
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    closeBlockUser () {
      this.popUpBlock = false
      this.blockText = ''
    },
    changeStatus () {
      this.popUpEdit = false
      const selectedStatusId = this.selectedStatusId

      if (selectedStatusId) {
        const id = this.getUserId()

        fetch(`${window.host}clients/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          },
          body: JSON.stringify({
            status: selectedStatusId
          })
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          const selectedStatus = this.originalStatuses.find(el => el.id === selectedStatusId)
          if (selectedStatus) {
            this.statusValue = selectedStatus.name
            this.userData.status = selectedStatus.name

            this.$store.commit('animateStore/animateSave')
          }
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }
    },
    setBlockText (value) {
      this.blockText = value
    },
    getUserId () {
      return this.$router.currentRoute.value.params.id
    },
    convertRange (range) {
      switch (range) {
        case 'max':
          return 'Макс.'
        case 'mid':
          return 'Сред.'
        case 'min':
          return 'Мин.'
      }

      return 'Нет покупок'
    },
    formatSum (sum, isBonusNumber = false) {
      const formatted = (new Intl.NumberFormat('ru-RU').format(sum)).replace(',', '.').split('.')

      if (!isBonusNumber) {
        if (formatted.length === 1) {
          formatted.push('00')
        } else if (formatted[1].length === 1) {
          formatted[1] = formatted[1] + '0'
        }
      }

      return formatted.join('.')
    }
  },
  created () {
    const id = this.getUserId()

    fetch(`${window.host}clients/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()
      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      const userData = data.data

      const birthday = userData.birthday.split('-')
      this.userData.birthday = [birthday[2], birthday[1], birthday[0]].join('.')

      this.userData.status = userData.status
      this.userData.name = userData.name
      this.userData.phone = userData.phone
      this.userData.antiquity = this.convertRange(userData.antiquity)
      this.userData.frequency = this.convertRange(userData.frequency)
      this.userData.average_check = this.convertRange(userData.average_check)
      this.userData.sum_group = this.convertRange(userData.sum_group)
      this.userData.recommend_from = userData.recommend_from
      this.userData.recommends = userData.recommends
      this.userData.purchase = userData.purchase
      this.userData.bonus_number = userData.bonus_number
      this.userData.active = !!userData.active
      this.userData.gender = userData.gender === 'female' ? 'Женский' : 'Мужской'
      this.userData.test = userData.test
      this.$emit('titleName', userData.name)

      fetch(window.host + 'users/activities', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        const adminData = data.data
        this.originalStatuses = adminData.statuses

        const selectedStatus = this.originalStatuses.find(el => el.name === this.userData.status)
        if (selectedStatus) {
          this.statusValue = selectedStatus.name
        }
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./customerInformation.scss" scoped/>
<style lang="scss" src="./../../components/textInput/textInput.scss" scoped/>
