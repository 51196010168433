<template>
  <div class="super-chat">
    <div class="x-chat" :class="!chatId && 'empty'">
      <div v-if="!chatId" class="chat-empty">
        <div class="chat-empty-message">
          {{ errorMessage }}
        </div>
      </div>
      <div v-else class="chat-wrapper">
        <div class="chat-body">
          <div class="chat-starter">
            Начало чата.
          </div>
          <div
            class="chat-item-wrap"
            :class="message.viewed === 1 ? '' : 'unreaded'"
            v-for="message in messages"
            :key="message.id"
            :ref="'message-' + message.id"
          >
            <div
              class="chat-item"
              :class="message.isManager && 'chat-item-manager'"
            >
              <div class="chat-item-content">
                <div class="chat-username">
                  {{ getMessageTitle(message) }}
                </div>
                <div class="chat-message ws-pw">
                  {{ message.message }}
                </div>
              </div>
              <div class="chat-msg-time">
                {{ message.time }}
              </div>
            </div>
          </div>
        </div>
        <div :class="footerClass">
          <div class="chat-input">
            <div class="input-wrapper">
              <div class="input-container">
                <textarea
                  rows="1"
                  class="input textarea"
                  type="text"
                  v-model="newMessage"
                  placeholder="Ваше сообщение..."
                  @focus="blockUpdatedScroll = true"
                ></textarea>
                <label class="chat-upload">
                  <input id="chat-file" type="file" accept=".jpg, .png, .jpeg, .gif, .svg, .pdf, .docx, .doc, .txt">
                  <icon name="clip" class="chat-upload-icon" />
                </label>
              </div>
            </div>
          </div>
          <div class="chat-send">
            <xButton size="small" @onClick="sendNewMessage">
              Отправить
            </xButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xButton from '@/components/xButton'
import Icon from '@/components/icons/icon'

export default {
  name: 'superChat',
  components: {
    Icon,
    xButton
  },
  props: {
    errorMessage: {
      type: String,
      default: 'Выберите чат слева, чтобы начать'
    },
    chatId: {
      type: [Number, Boolean],
      default: false
    },
    chatUserName: {
      type: [Number, Boolean],
      default: false
    }
  },
  data () {
    return {
      messages: [],
      newMessage: '',
      newMessageLines: 1,
      blockUpdatedScroll: false
    }
  },
  updated: function () {
    this.$nextTick(function () {
      if (!this.blockUpdatedScroll) {
        this.scrollToUnreaded()
      }
    })
  },
  watch: {
    chatId: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.requestMessages(newVal, oldVal)
      }
    },
    newMessage: function (newVal) {
      this.newMessageLines = newVal.split(/\n/).length
    }
  },
  computed: {
    footerClass () {
      const classesArr = ['chat-footer']

      if (this.newMessageLines > 1) {
        classesArr.push('multilines-lines')
      }

      if (this.newMessageLines === 2) {
        classesArr.push('two-lines')
      }

      return classesArr.join(' ')
    }
  },
  methods: {
    getMessageTitle (message) {
      const isManager = message.isManager
      if (!isManager) {
        return this.chatUserName
      }

      if (this.$store.state.techStore.companyName) {
        return this.$store.state.techStore.companyName
      }

      return 'Менежер'
    },
    scrollToEnd: function () {
      const container = this.$el.querySelector('.chat-body')
      if (container) {
        container.scrollTop = container.scrollHeight
      }
    },
    scrollToUnreaded: function () {
      let firstUnreadedMessageI = this.messages.findIndex(el => el.viewed !== 1)
      if (firstUnreadedMessageI !== -1) {
        if (firstUnreadedMessageI !== 0) {
          firstUnreadedMessageI = firstUnreadedMessageI - 1
        }
        const idToScroll = this.messages[firstUnreadedMessageI].id
        this.$refs['message-' + idToScroll][0].scrollIntoView()

        setTimeout(() => {
          this.readUnreadedMessages()
        }, 2000)
      } else {
        this.scrollToEnd()
      }

      setTimeout(() => {
        this.$emit('readUnanswered')
      }, 500)
    },
    readUnreadedMessages () {
      this.messages.map(el => {
        if (el.viewed !== 1) {
          el.viewed = 1
        }
        return el;
      })
    },
    convertTime (dateTime) {
      const dateArr = dateTime.split('.')
      const dateObj = new Date([dateArr[1], dateArr[0], dateArr[2]].join('.'))

      const day = ('0' + dateObj.getDate()).slice(-2)
      const month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
      const date = `${day}.${month}.${dateObj.getFullYear()}`

      const hours = ('0' + dateObj.getHours()).slice(-2)
      const minutes = ('0' + dateObj.getMinutes()).slice(-2)
      const time = `${hours}:${minutes}`

      return `${date} ${time}`
    },
    sendNewMessage () {
      this.blockUpdatedScroll = false

      if (this.newMessage.trim().length) {
        fetch(`${window.host}dialogs/${this.chatId}/messages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          },
          body: JSON.stringify({
            message: this.newMessage
          })
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          const dateTime = data.data.created_at.split('T')
          const date = dateTime[0].split('-')
          const time = dateTime[1].split('.')[0].split(':')

          this.messages.push({
            id: data.data.id,
            viewed: data.data.viewed,
            message: data.data.message,
            time: this.convertTime(`${[date[2], date[1], date[0]].join('.')} ${time[0]}:${time[1]}`),
            isManager: !data.data.incoming
          })

          this.newMessage = ''
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }
    },
    requestMessages: function (newVal, oldVal = false) {
      if (oldVal) {
        this.$store.commit('socketStore/unsubscribe', { channel: `private-chatbox.${oldVal}` })
      }

      this.$store.commit('socketStore/subscribe', {
        channel: `private-chatbox.${newVal}`,
        eventName: 'App\\Events\\MessageSent',
        callback: data => {
          const dateTime = data.message.created_at.split('T')
          const date = dateTime[0].split('-')
          const time = dateTime[1].split('.')[0].split(':')

          this.messages.push({
            message: data.message.message,
            time: this.convertTime(`${[date[2], date[1], date[0]].join('.')} ${time[0]}:${time[1]}`),
            isManager: !data.message.incoming
          })
        }
      })

      fetch(`${window.host}dialogs/${newVal}/messages`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.messages = data.data.map(el => {
          return {
            id: el.id,
            message: el.message,
            viewed: el.viewed,
            time: this.convertTime(el.created_at),
            isManager: !el.incoming
          }
        })

        this.scrollToUnreaded()
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    }
  },
  created () {
    if (!this.messages.length && this.chatId) {
      this.requestMessages(this.chatId)
    }

    this.$nextTick(function () {
      this.scrollToUnreaded()
    })
  }
}
</script>

<style lang="scss" src="./superChat.scss" scoped/>
<style lang="scss" src="./../textInput/textInput.scss" scoped/>
