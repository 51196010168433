<template>
  <div class="layout-1">
    <div class="recommendation">
      <h2 class="title">Бонусы рекомендателю за приведенного клиента</h2>
      <div class="recommendation-input">
        <div class="x-input-item">
          <div class="input-name">
            <span class="note-wrapper">
              Бонус за рекомендацию
            <note note-position="bottom-to-right">
              Используйте эту настройку, если вы не хотите привязывать вознаграждение рекомендателя к сумме покупок друга по рекомендации.
              <br><br>
              Эту настройку целесообразно использовать, когда имеет место огромный разброс в суммах покупок.
              <br><br>
              Эту настройку можно использовать совместно с реферальной программой.
              <br><br>
              Если вы не хотите использовать этот вид вознаграждений, поставьте 0.
            </note>
            </span>
          </div>
          <div class="input-content">
            <div class="input-wrapper" :class="errors.recommendBonus && 'p-r'">
              <div class="input-container" :class="errors.recommendBonus && 'error-border'">
                <numberFormat
                  class="input"
                  v-model:value="formData.recommendBonus"
                  :options="formatSum"
                  @blur="e => validateSum('recommendBonus', e.target.value, 0)"
                ></numberFormat>
                <div class="float">р.</div>
              </div>
              <span class="error" v-if="errors.recommendBonus">{{ errors.recommendBonus }}</span>
            </div>
          </div>
        </div>
        <p class="desc">
          Вознаграждение клиенту (фиксированная сумма) за покупки друга по его рекомендации
        </p>
      </div>
      <div class="changer">
        <div class="changer-item">
          <label class="label">
            <input class="radio" type="radio" name="recommendBonusAward" value="0" v-model="formData.recommendBonusAward"/>
            <span class="checkmark"></span>
            <span class="label-text">
              Начислять за ПЕРВУЮ покупку
            </span>
          </label>
        </div>
        <div class="changer-item">
          <label class="label">
            <input class="radio" type="radio" name="recommendBonusAward" value="1" v-model="formData.recommendBonusAward"/>
            <span class="checkmark"></span>
            <span class="label-text">
              Начислять за КАЖДУЮ покупку
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="system">
      <div class="title note-wrapper">
        Реферальная система
        <note note-position="bottom-to-right">
          Используйте эту настройку, чтобы мотивировать клиентов делать рекомендации.
          <br><br>
          Найдите баланс: это должно быть «вкусно» для клиента и неубыточно для вас.
          <br><br>
          Рекомендуем начислять бонусы за КАЖДУЮ покупку, т.к. при каждой покупке друга ваш клиент будет получать сообщение о начислении бонусов: его это будет мотивировать делать собственные покупки у вас и еще больше рекомендовать.
        </note>
      </div>
      <p class="desc">Вознаграждение клиенту в процентах от покупки друга и его друзей</p>
      <div class="system-level">
        <div class="system-level-item">
          <div class="x-input-item">
            <div class="input-name">1 уровень</div>
            <div class="input-content">
              <div class="input-wrapper" :class="errors.referralLevel1 && 'p-r'">
                <div class="input-container" :class="errors.referralLevel1 && 'error-border'">
                  <input class="input" v-model="formData.referralLevel1" @blur="e => validatePercent('referralLevel1', e.target.value, 0)">
                  <div class="float">%</div>
                </div>
                <span class="error" v-if="errors.referralLevel1">{{ errors.referralLevel1 }}</span>
              </div>
            </div>
          </div>
          <p class="system-level-desc">
            Иван порекомендовал Николаю. Это процент с покупок Николая
          </p>
        </div>
        <div class="system-level-item">
          <div class="x-input-item">
            <div class="input-name">2 уровень</div>
            <div class="input-content">
              <div class="input-wrapper" :class="errors.referralLevel2 && 'p-r'">
                <div class="input-container" :class="errors.referralLevel2 && 'error-border'">
                  <input class="input" v-model="formData.referralLevel2" @blur="e => validatePercent('referralLevel2', e.target.value, 0)">
                  <div class="float">%</div>
                </div>
                <span class="error" v-if="errors.referralLevel2">{{ errors.referralLevel2 }}</span>
              </div>
            </div>
          </div>
          <p class="system-level-desc">
            Потом Николай порекомендовал Сергею. Это процент Ивану с покупок Сергея
          </p>
        </div>
        <div class="system-level-item">
          <div class="x-input-item">
            <div class="input-name">3 уровень</div>
            <div class="input-content">
              <div class="input-wrapper" :class="errors.referralLevel3 && 'p-r'">
                <div class="input-container" :class="errors.referralLevel3 && 'error-border'">
                  <input class="input" v-model="formData.referralLevel3" @blur="e => validatePercent('referralLevel3', e.target.value, 0)">
                  <div class="float">%</div>
                </div>
                <span class="error" v-if="errors.referralLevel3">{{ errors.referralLevel3 }}</span>
              </div>
            </div>
          </div>
          <p class="system-level-desc">
            Потом Сергей порекомендовал Петру. Это процент Ивану с покупок Петра
          </p>
        </div>
      </div>
      <div class="changer">
        <div class="changer-item">
          <label class="label">
            <input class="radio" type="radio" value="0" name="referralAward" v-model="formData.referralAward"/>
            <span class="checkmark"></span>
            <span class="label-text">
              Начислять за ПЕРВУЮ покупку
            </span>
          </label>
        </div>
        <div class="changer-item">
          <label class="label">
            <input class="radio" type="radio" value="1" name="referralAward" v-model="formData.referralAward"/>
            <span class="checkmark"></span>
            <span class="label-text">
              Начислять за КАЖДУЮ покупку
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="referrals-footer">
      <p class="footer-desc">
        После сохранения зайдите на вкладку <b><router-link to="/admin/settings/service" class="link">Сообщения / Сервисные</router-link></b> сообщения и посмотрите, какие сообщения будут отправляться клиентам. При необходимости скорректируйте сообщения.
      </p>
      <div class="referrals-footer-button">
        <xButton @click="onSendForm">
          Сохранить
        </xButton>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import note from '@/components/note'
import xButton from '@/components/xButton'
import numberFormat from '@/components/numberFormat'

const validatePercent = debounce((val, min, successCall, errorCall) => {
  const minVal = min !== false ? min : 0.01

  const toFloat = parseFloat(val.toString().replace(',', '.'))
  if (isNaN(toFloat)) {
    return errorCall('Значение введено с ошибкой')
  }

  if (toFloat < minVal) {
    return errorCall('Значение не может быть меньше ' + minVal)
  }
  if (toFloat > 100.00) {
    return errorCall('Значение не может быть больше 100')
  }

  const splitted = toFloat.toString().split('.')
  if (splitted.length === 2) {
    if (splitted[1].length >= 2) {
      splitted[1] = splitted[1].slice(0, 2)
    } else {
      splitted[1] = splitted[1] + '0'
    }
  }
  successCall(splitted.join('.'))
}, 0)
const validateSum = debounce((val, min, successCall, errorCall) => {
  const minVal = min !== false ? min : 1

  const toInt = parseInt(val.replace(/\D/gmu, ''))
  if (isNaN(toInt)) {
    return errorCall('Значение введено с ошибкой')
  }

  if (toInt < minVal) {
    return errorCall('Значение не может быть меньше ' + minVal)
  }
  if (toInt > 1000000000) {
    return errorCall('Значение не может быть больше 1 000 000 000')
  }

  successCall(toInt)
}, 0)

export default {
  name: 'settingsReferrals',
  components: {
    note,
    xButton,
    numberFormat
  },
  data () {
    return {
      bonusStatusesSum: 0,
      formatSum: {
        decimal: '',
        thousand: ' ',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },
      formError: '',
      isFormLoaded: false,
      formData: {
        recommendBonus: '',
        recommendBonusAward: '',
        referralLevel1: '',
        referralLevel2: '',
        referralLevel3: '',
        referralAward: ''
      },
      errors: {
        referralLevel1: '',
        referralLevel2: '',
        referralLevel3: '',
        recommendBonus: ''
      }
    }
  },
  watch: {
    'formData.recommendBonus' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.recommendBonusAward' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.referralLevel1' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.referralLevel2' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.referralLevel3' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    },
    'formData.referralAward' () {
      if (this.isFormLoaded) {
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: true,
            callback: this.onSendForm
          }
        )
      }
    }
  },
  computed: {
    isHaveErrors () {
      const errors = []
      for (const key in this.errors) {
        errors.push(this.errors[key])
      }
      return !!errors.join('').length || !!this.isStatusError
    },
    isHaveEmptyFields () {
      for (const key in this.formData) {
        if (!this.formData[key].toString().length) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    scrollToErrors () {
      const firstErrElem = window.document.querySelector('.error-border')
      if (firstErrElem) {
        const offset = firstErrElem.offsetTop - 100
        window.scroll({
          top: offset < 0 ? 0 : offset,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
    checkEmptyFields () {
      const checkThisFields = [
        'recommendBonus',
        'referralLevel1',
        'referralLevel2',
        'referralLevel3'
      ]
      for (const formKey of checkThisFields) {
        this.errors[formKey] = !this.formData[formKey].toString().trim().length
          ? 'Заполните поле'
          : ''
      }
    },
    validatePercent (variable, val, min = false) {
      if (val.trim() === '') {
        return
      }
      const replacedVal = val.replace(/%/, '')
      this.formData[variable] = replacedVal

      this.errors[variable] = ''
      validatePercent(
        replacedVal,
        min,
        newVal => {
          this.formData[variable] = newVal
        },
        errString => {
          this.errors[variable] = errString
        }
      )
    },
    validateSum (variable, val, min = false) {
      if (val.trim() === '') {
        return
      }
      const replacedVal = val.replace(/р\./, '')
      this.formData[variable] = replacedVal

      this.errors[variable] = ''

      validateSum(
        replacedVal,
        min,
        newVal => {
          this.formData[variable] = newVal
        },
        errString => {
          this.errors[variable] = errString
        }
      )
    },
    onSendForm () {
      const allBonusSum =
        parseInt(this.formData.referralLevel1) +
        parseInt(this.formData.referralLevel2) +
        parseInt(this.formData.referralLevel3) +
        this.bonusStatusesSum

      if (allBonusSum > 100) {
        this.$store.commit(
          'popupStore/show',
          {
            text: 'Сумма бонусов по всем статусам и вознаграждениям за рекомендации не может превышать 100%. Скорректируйте процент начисляемых бонусов.',
            buttonText: 'Закрыть',
            useFullWindow: false
          }
        )
        return
      }

      this.checkEmptyFields()

      if (this.isHaveErrors || this.isHaveEmptyFields) {
        this.scrollToErrors()
        return
      }

      const formData = {
        ...this.formData
      }
      const formatFormData = {
        recommendBonus: parseInt(formData.recommendBonus),
        recommendBonusAward: formData.recommendBonusAward,
        referralLevel1: parseFloat(formData.referralLevel1),
        referralLevel2: parseFloat(formData.referralLevel2),
        referralLevel3: parseFloat(formData.referralLevel3),
        referralAward: formData.referralAward,
        statuses: this.bonusesForRequest
      }

      fetch(window.host + 'users/activities/' + this.formData.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify(formatFormData)
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.$store.commit('animateStore/animateSave')
        this.$store.commit(
          'techStore/setFormChanged',
          {
            isChanged: false
          }
        )
      }).catch(err => {
        this.formError = err
        this.$store.commit('techStore/checkError', { err })
      })
    }
  },
  created () {
    fetch(window.host + 'users/activities', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      const activityData = data.data

      const referralLevel1Split = activityData.referralLevel1 ? activityData.referralLevel1.toString().split('.') : ['']
      if (referralLevel1Split.length === 2 && referralLevel1Split[1].length === 1) {
        referralLevel1Split[1] += '0'
      }
      const collectReferralLevel1Split = referralLevel1Split.join('.')

      const referralLevel2Split = activityData.referralLevel2 ? activityData.referralLevel2.toString().split('.') : ['']
      if (referralLevel2Split.length === 2 && referralLevel2Split[1].length === 1) {
        referralLevel2Split[1] += '0'
      }
      const collectReferralLevel2Split = referralLevel2Split.join('.')

      const referralLevel3Split = activityData.referralLevel3 ? activityData.referralLevel3.toString().split('.') : ['']
      if (referralLevel3Split.length === 2 && referralLevel3Split[1].length === 1) {
        referralLevel3Split[1] += '0'
      }
      const collectReferralLevel3Split = referralLevel3Split.join('.')

      this.formData = Object.assign(this.formData, {
        id: activityData.id,
        recommendBonus: activityData.recommendBonus,
        referralLevel1: collectReferralLevel1Split ? `${collectReferralLevel1Split}` : '0',
        referralLevel2: collectReferralLevel2Split ? `${collectReferralLevel2Split}` : '0',
        referralLevel3: collectReferralLevel3Split ? `${collectReferralLevel3Split}` : '0',
        recommendBonusAward: activityData.recommendBonusAward,
        referralAward: activityData.referralAward
      })

      setTimeout(() => {
        this.isFormLoaded = true
      }, 500)

      if (activityData.statuses.length) {
        const currentValue = activityData.statuses[activityData.statuses.length - 1]
        const bonusSplit = currentValue.bonus ? currentValue.bonus.toString().split('.') : ['']
        if (bonusSplit.length === 2 && bonusSplit[1].length === 1) {
          bonusSplit[1] += '0'
        }
        const collectBonusSplit = bonusSplit.join('.')

        this.bonusStatusesSum = collectBonusSplit ? parseInt(collectBonusSplit) : '─'
      }
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./settingsReferrals.scss" scoped />
<style lang="scss" src="./../../components/textInput/textInput.scss" scoped/>
