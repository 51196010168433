<template>
  <div class="msg-header">
    <div class="msg-header-button">
      <xButton @click="$router.push('/admin/messages/create')">
        <icon class="xbutton-icon" name="create"></icon>
        Создать
      </xButton>
    </div>
    <div class="msg-header-notify">
      <adminNotify color="orange">
        <span class="note-wrapper">
          Не рекомендуем создавать избыточное количество сообщений без бонусов – нет клиентов, которые любят спам
          <note>
            Вы можете автоматически отправлять клиенту сообщения при наступлении важных событий в системе. Например:
            <br><br>
            Покупка друга по рекомендации – поблагодарите клиента за то, что он вас рекомендует.
            <br><br>
            Совершения покупки №___ - клиент уже давно с вами – поблагодарите его за это и подарите какой-то бонус.
            <br><br>
            Х дней без покупок – клиент давно не делает покупки… возможно, начал покупать у конкурентов. Подарите ему бонусы и пригласите к себе.
            <br><br>
            День рождения – поздравьте клиента и подарите подарок.
            <br><br>
            И т.д.
          </note>
          </span>
      </adminNotify>
    </div>
  </div>
  <div class="table-container">
    <xTable v-bind="getTableData()">
      <template v-slot="{ records }">
        <td>
          <div class="table-action">
            <router-link to="/admin/messages/create">
              <icon @click="slotProps(records, 'Редактировать')" name="edit" class="table-icon"/>
            </router-link>
            <button @click="slotProps(popUpCopy = true, records, 'Копировать')">
              <icon name="copy" class="table-icon"/>
            </button>
            <button @click="slotProps(popUpDelete = true, records, 'Удалить')">
              <icon name="delete" class="table-icon"/>
            </button>
          </div>
        </td>
      </template>
    </xTable>
  </div>
  <pop-up :open="popUpCopy" @close="popUpCopy = !popUpCopy" sizer="pop-small">
    <div class="x-pop-content__center">
      <p class="x-pop-desc-black">
        После создания копии цепочки авто-сообщений обязательно внесите изменения. Если вы этого не сделаете, клиенту будет отправляться 2 одинаковых копии сообщений
      </p>
      <div class="x-pop-footer__center">
        <xButton>Продолжить</xButton>
        <button @click="popUpCopy = false" class="link x-pop-back">Отмена</button>
      </div>
    </div>
  </pop-up>
  <pop-up :open="popUpDelete" @close="popUpDelete = !popUpDelete" sizer="pop-small">
      <h2 class="x-pop-title">Вы удаляете цепочку сообщений</h2>
      <p class="x-pop-desc-black">
        Все уже запланированные сообщения будут отправлены. Все уже запланированные действия с бонусами (например, сгорание начисленного ранее и не использованного бонуса) будут выполнены. Новые сообщения и начисления бонусов более не будут отправляться. Пожалуйста, подтвердите удаление
      </p>
      <div class="x-pop-footer">
        <xButton>Удалить</xButton>
        <button @click="popUpDelete = false" class="link x-pop-back">Отмена</button>
      </div>
  </pop-up>
</template>

<script>
import adminNotify from '@/components/adminNotify'
import xButton from '@/components/xButton'
import xTable from '@/components/xTable'
import messagesEvents from '@/views/messagesEvents/messagesEvents.json'
import note from '@/components/note'
import popUp from '@/components/popUp'

export default {
  name: 'messagesEvents',
  components: {
    xButton,
    xTable,
    adminNotify,
    note,
    popUp
  },
  data () {
    return {
      popUpCopy: false,
      popUpDelete: false
    }
  },
  methods: {
    getTableData () {
      return {
        theads: 1,
        align: 'left',
        messagesEvents: true,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: messagesEvents,
        wordChecker: true,
        optionsX: [
          {
            name: 'Наименование сообщения',
            value: 'message',
            id: 0
          }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Наименование сообщения'],
        namesForHeaderX: []
      }
    }
  }
}
</script>

<style lang="scss" src="./messagesEvents.scss" scoped/>
