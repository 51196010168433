import { createStore } from 'vuex'
import techStore from './stores/techStore'
import animateStore from './stores/animateStore'
import popupStore from './stores/popupStore'
import socketStore from './stores/socketStore'

const store = createStore({
  modules: {
    techStore,
    animateStore,
    popupStore,
    socketStore
  }
})

export default store
