import { createApp } from 'vue'
import App from './App.vue'
import icon from '@/components/icons'
import router from './router'
import store from './store/store'
import SmartTable from 'vuejs-smart-table'
import VueformMultiselect from '@vueform/multiselect'
import VueTheMask from 'vue-the-mask'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App)

app.use(store)

app.use(VueReCaptcha, { siteKey: '6Leb-J0hAAAAAA0c2LGFJ46LC20W3fJi2WCAIDss' })

// global registration - can be used anywhere
app.component('icon', icon)
app.component('Multiselect', VueformMultiselect)

app.use(router)
app.use(SmartTable)
app.use(VueTheMask)
// app.use(Multiselect)
// app.use(Multiselect)

app.mount('#app')

const schema = 'https://'
const preffix = '/api/v1/'

window.siteName = 'bonusplan.online'

window.domen = schema + window.siteName
window.host = window.domen + preffix

window.privateDomen = schema + 'private.' + window.siteName
window.privateHost = window.privateDomen + preffix
