<template>
  <div class="msg-container">
    <adminNotify color="orange">
      Внимание! Обычно эти сообщения не требуется редактировать.
      <br>
      Вносите изменения, только если вы уверены в их необходимости. После внесения изменений протестируйте работу сообщений
    </adminNotify>
    <div class="msg-table">
      <div class="msg-table-wrapper">
        <xTable v-bind="tableData">
          <template v-slot="{ records }" >
            <td class="table-action-toright">
              <button @click="editAction(records.id)" class="msg-table-edit">
                <icon name="edit" class="table-icon" />
                <span class="link">Редактировать</span>
              </button>
              <button @click="beforeResetAction(records.id)" class="msg-table-edit ml-10">
                <span class="link" style="white-space: nowrap">Сбросить изменения</span>
              </button>
            </td>
          </template>
        </xTable>
      </div>
    </div>
  </div>
  <pop-up :open="resetPopup" @close="closeReset" sizer="pop-verysmall">
    <div class="x-pop-content__center">
      <h2 class="x-pop-title">Все внесенные изменения будут потеряны.</h2>
      <p class="x-pop-desc-black">
        Будут созданы стандартные сообщения по умолчанию
      </p>
      <div class="x-pop-footer__center">
        <xButton @click="resetAction">Вернуть настройки по умолчанию</xButton>
        <button @click="closeReset" class="link x-pop-back">Отмена</button>
      </div>
    </div>
  </pop-up>
  <pop-up :open="editPopup" @close="closeEdit" sizer="pop-customsize">
    <div class="x-pop-content__center">
      <h2 class="x-pop-title with-help">
        Редактирование сообщения
        <note icon-size="note-small inline" note-position="bottom-to-left" v-if="isHelp" :html="helpPopupText" :commands="commands" :hasCommands="hasCommands" :getRef="() => $refs['superText']"></note>
      </h2>
      <div class="input-wrapper mb-20" :class="!!editPopupTextError && 'error-wrap'">
        <div class="input-container with-textarea">
          <div class="input textarea textarea-message" ref="superText" @click="deleteText" contenteditable="true" v-html="editPopupText" @keyup="findCommands"></div>
          <textarea class="input textarea textarea-message" v-model="editPopupText" style="display: none"></textarea>
        </div>
        <span v-if="editPopupTextError" class="error">{{ editPopupTextError }}</span>
      </div>

      <div v-if="hasCommands" class="commands-line">
        <p v-for="command in commands" :key="command.id" :title="command.text">
          <input @click="insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@' + command.name + '</span>')" class="link clicker-tech-link" type="button" v-bind:value="'@'+command.name">
        </p>
      </div>

      <div class="input-wrapper mb-20 dont-send">
        <checkboxInput
          :checked="sendDisabled"
          :name="'Не отправлять сообщение'"
          @isChecked="val => changeDisabled(val)"
        />
      </div>

      <div class="x-pop-footer__center">
        <xButton @click="saveAction">Сохранить изменения</xButton>
        <button @click="testAction" class="link x-pop-back">Отправить тест</button>
        <button @click="closeEdit" class="link x-pop-back">Отмена</button>
      </div>
    </div>
  </pop-up>
</template>

<script>
import adminNotify from '@/components/adminNotify'
import xTable from '@/components/xTable'
import xButton from '@/components/xButton'
import popUp from '@/components/popUp'
import note from '@/components/note'
import checkboxInput from '@/components/checkboxInput'

export default {
  name: 'messagesService',
  components: {
    adminNotify,
    xTable,
    xButton,
    popUp,
    note,
    checkboxInput
  },
  data () {
    return {
      operableId: false,
      editPopup: false,
      isHelp: false,
      editPopupText: '',
      helpPopupText: '',
      editPopupTextError: '',
      resetPopup: false,
      originalMessages: [],
      commands: [],
      hasCommands: false,
      sendDisabled: false,
      tableData: {
        theads: 1,
        align: 'left',
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [],
        wordChecker: true,
        optionsX: [
          { name: 'Наименование сообщения', value: 'message', id: 0 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Наименование сообщения'],
        namesForHeaderX: []
      }
    }
  },
  watch: {
    editPopupText: function (newVal) {
      if (newVal.length && this.editPopupTextError.length) {
        this.editPopupTextError = ''
      }
    }
  },
  methods: {
    insertText (html, selectPastedContent) {
      const ref = this.$refs.superText;
      ref.focus();

      let sel, range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0)
          range.deleteContents()
          const el = document.createElement('div')
          el.innerHTML = html
          const frag = document.createDocumentFragment(); let node; let lastNode
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node)
          }
          const firstNode = frag.firstChild
          range.insertNode(frag)

          if (lastNode) {
            range = range.cloneRange()
            range.setStartAfter(lastNode)
            if (selectPastedContent) {
              range.setStartBefore(firstNode)
            } else {
              range.collapse(true)
            }
            sel.removeAllRanges()
            sel.addRange(range)
          }
        }
      } else if ((sel = document.selection) && sel.type !== 'Control') {
        // IE < 9
        const originalRange = sel.createRange()
        originalRange.collapse(true)
        sel.createRange().pasteHTML(html)
        if (selectPastedContent) {
          range = sel.createRange()
          range.setEndPoint('StartToStart', originalRange)
          range.select()
        }
      }
    },
    findCommands (e) {
      if (this.commands.length > 0) {
        this.commands.forEach(item => {
          if (e.target.innerHTML.indexOf('@' + item.name)) {
            console.log('find!')
          }
        })
      }
    },
    changeDisabled (val) {
      this.sendDisabled = val
    },
    copyData () {
      this.editPopupText = this.$refs.superText.innerHTML
    },
    closeReset () {
      this.operableId = false
      this.resetPopup = false
    },
    beforeResetAction (id) {
      this.operableId = id
      this.resetPopup = true
    },
    resetAction () {
      const _this = this
      if (this.operableId) {
        fetch(`${window.host}notifications/${this.operableId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          },
          body: JSON.stringify({
            reset: true
          })
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          _this.originalMessages.map(function (item, key) {
            if (item.id === data.data.id) {
              _this.originalMessages[key] = data.data;
            }
            return true;
          })

          this.closeReset()
          this.$store.commit('animateStore/animateSave', { text: 'Изменения сброшены' })
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
          this.closeReset()
        })
      } else {
        this.closeReset()
      }
    },
    closeEdit () {
      this.editPopup = false
      this.operableId = false
      this.isHelp = false
      this.editPopupText = ''
      this.helpPopupText = ''
      this.editPopupTextError = ''
      this.commands = []
      this.hasCommands = false
      this.sendDisabled = false
    },
    editAction (id) {
      const findEl = this.originalMessages.find(el => el.id === id)
      if (findEl) {
        this.operableId = id
        this.editPopupText = findEl.text
        this.helpPopupText = findEl.help ?? ''
        this.editPopup = true
        this.isHelp = this.helpPopupText.length > 0
        this.commands = findEl.commands ?? []
        this.hasCommands = this.commands.length > 0
        this.sendDisabled = !findEl.active
      }
    },
    saveAction () {
      const _this = this
      this.copyData()

      if (this.operableId) {
        if (this.editPopupText.length) {
          fetch(`${window.host}notifications/${this.operableId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify({
              text: this.editPopupText,
              active: !this.sendDisabled
            })
          }).then(async response => {
            const data = await response.json()

            if (!response.ok || !data.success) {
              const error = (data && data.message) || response.status
              return Promise.reject(error)
            }

            _this.originalMessages.map(function (item, key) {
              if (item.id === data.data.id) {
                _this.originalMessages[key] = data.data;
              }
              return true;
            })

            this.closeEdit()
            this.$store.commit('animateStore/animateSave')
          }).catch(err => {
            this.$store.commit('techStore/checkError', { err })
            this.closeEdit()
          })
        } else {
          this.editPopupTextError = 'Введите новое сообщение'
        }
      } else {
        this.closeEdit()
      }
    },
    deleteText (e) {
      if (e.target.className === 'link clicker-link') {
        e.target.remove()
      }
    },
    testAction () {
      // const _this = this
      this.copyData()

      if (this.operableId) {
        fetch(`${window.host}notifications/test/${this.operableId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          }
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          this.closeEdit()
          this.$store.commit('animateStore/animateSave', { text: 'Тестовое сообщение отправлено' })
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
          this.closeEdit()
        })
      } else {
        this.editPopupTextError = 'Введите новое сообщение'
      }
    }
  },
  created () {
    fetch(window.host + 'notifications', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      const messageData = data.data

      this.originalMessages = messageData
      this.tableData.data = messageData.map(el => ({
        id: el.id,
        message: el.name
      }))
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./messagesService.scss" scoped />
<style lang="scss" src="../../components/textInput/textInput.scss" scoped />
