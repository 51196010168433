<template>
  <div class="layout-1">
    <div class="create-header create-block">
      <div class="x-input-item">
        <div class="input-name">Что сделать</div>
        <div class="input-content">
          <Multiselect
            v-model="value"
            :options="action"
            placeholder="Начислить бонусы и отправить сообщение"
            :canClear="false"
          />
        </div>
      </div>
      <div class="create-event">
        <div class="x-input-item">
          <div class="input-name">Когда отправлять</div>
          <div class="input-content">
            <Multiselect
              v-model="value"
              :options="timer"
              placeholder="Сразу после"
              :canClear="false"
            />
          </div>
        </div>
        <div class="x-input-item">
          <div class="input-name">Событие</div>
          <div class="input-content">
            <Multiselect
              v-model="value"
              :options="xEvent"
              placeholder="Совершение покупки"
              :canClear="false"
            />
          </div>
        </div>
      </div>
      <div class="create-conditions">
        <div class="create-conditions-bonuses">
          <div class="x-input-item">
            <div class="input-name">Начислить клиенту</div>
            <div class="input-content">
              <textInput default-value="500" />
            </div>
            <div class="create-conditions-afterword">бонусов</div>
          </div>
        </div>
        <div class="create-conditions-remove">
          <div class="x-input-item">
            <div class="input-name">Сгорают</div>
            <div class="input-content">
              <Multiselect
                v-model="value"
                :options="createConditionsRemove"
                placeholder="Да"
                :canClear="false"
              />
            </div>
          </div>
        </div>
        <div class="create-conditions-term">
          <div class="x-input-item">
            <div class="input-name">Через</div>
            <div class="input-content">
              <textInput default-value="3" />
            </div>
            <div class="create-conditions-afterword">дня</div>
          </div>
        </div>
      </div>
    </div>
    <div class="create-message create-block">
      <div class="x-input-item">
        <div class="input-name">Заголовок</div>
        <div class="input-content">
          <textInput placeholder="Напишите название сообщения, например “К нам приехала коллекция”" />
        </div>
      </div>
      <div class="create-message-send">
        <div class="note-wrapper">
          <h2 class="title">
            Отправить сообщение
          </h2>
          <note note-position="bottom-to-right">
            <b>
              Сплочённость команды профессионалов продолжает удивлять.
            </b>
            <br><br>
            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний, из которых в опредёленном порядке формируются предложения.
            <br><br>
            Предложения складываются в абзацы – и вы наслаждетесь очередным бредошедевром.
            <br><br>
            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей"
          </note>
        </div>
          <div class="create-message-body">
            <div class="text-wrapper" contenteditable='false'>
              <div class="input-wrapper">
                <div class="input-container">
                  <div class="input-clicker" @click="deleteText" ref="superText" contenteditable="true"></div>
                </div>
              </div>
              <div class="clicker-tech">
                <input @click="this.$refs.superText.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Имя_Клиента</span>')" class="link clicker-tech-link" type="button" value="@Имя_Клиента">
                <input @click="this.$refs.superText.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Сумма_Покупки</span>')" class="link clicker-tech-link" type="button" value="@Сумма_Покупки">
                <input @click="this.$refs.superText.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Сумма_Бонусов</span>')" class="link clicker-tech-link" type="button" value="@Сумма_Бонусов">
                <input @click="this.$refs.superText.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Статус</span>')" class="link clicker-tech-link" type="button" value="@Статус">
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="create-message-reminder create-block">
      <div class="x-input-item">
        <div class="input-name">Сообщить о скором сгорании бонусов</div>
        <div class="input-content">
          <Multiselect
            v-model="value"
            :options="reminder"
            placeholder="Да"
            :canClear="false"
          />
        </div>
      </div>
      <div class="x-input-item x-input-item-timer">
        <div class="input-name">за</div>
        <div class="input-content">
          <textInput default-value="7" />
        </div>
        <div class="create-conditions-afterword">дней до сгорания</div>
      </div>
    </div>
    <div class="create-message-notify create-block">
      <div class="x-input-item">
        <div class="input-name">Сообщить о факте сгорании бонусов</div>
        <div class="input-content">
          <Multiselect
            v-model="value"
            :options="notify"
            placeholder="Да"
            :canClear="false"
          />
        </div>
      </div>
      <div class="create-message-send">
        <h2 class="title">Отправить сообщение</h2>
        <div class="create-message-body">
          <div class="create-message-body">
            <div class="text-wrapper" contenteditable='false'>
              <div class="input-wrapper">
                <div class="input-container">
                  <div class="input-clicker" @click="deleteText" ref="superText2" contenteditable="true"></div>
                </div>
              </div>
              <div class="clicker-tech">
                <input @click="this.$refs.superText2.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Имя_Клиента</span>')" class="link clicker-tech-link" type="button" value="@Имя_Клиента">
                <input @click="this.$refs.superText2.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Сумма_Покупки</span>')" class="link clicker-tech-link" type="button" value="@Сумма_Покупки">
                <input @click="this.$refs.superText2.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Сумма_Бонусов</span>')" class="link clicker-tech-link" type="button" value="@Сумма_Бонусов">
                <input @click="this.$refs.superText2.focus(); insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@Статус</span>')" class="link clicker-tech-link" type="button" value="@Статус">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="create-message-footer">
      <div class="create-message-footer__save">
        <xButton>Сохранить</xButton>
      </div>
      <button class="link" @click="$router.go(-1)">Закрыть</button>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'
import note from '@/components/note'

export default {
  components: {
    textInput,
    xButton,
    note
  },
  name: 'messagesCreate',
  data () {
    return {
      value: null,
      action: [
        'Начислить бонусы и отправить сообщение',
        'Только начислить бонусы'
      ],
      timer: [
        'Сразу после',
        'Через 1 час'
      ],
      createConditionsRemove: [
        'Да',
        'Нет'
      ],
      reminder: [
        'Да',
        'Нет'
      ],
      notify: [
        'Да',
        'Нет'
      ],
      xEvent: [
        'Совершение покупки',
        'Открытия магазина'
      ]
    }
  },
  methods: {
    insertText (html, selectPastedContent) {
      let sel, range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0)
          range.deleteContents()
          const el = document.createElement('div')
          el.innerHTML = html
          const frag = document.createDocumentFragment(); let node; let lastNode
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node)
          }
          const firstNode = frag.firstChild
          range.insertNode(frag)

          if (lastNode) {
            range = range.cloneRange()
            range.setStartAfter(lastNode)
            if (selectPastedContent) {
              range.setStartBefore(firstNode)
            } else {
              range.collapse(true)
            }
            sel.removeAllRanges()
            sel.addRange(range)
          }
        }
      } else if ((sel = document.selection) && sel.type !== 'Control') {
        // IE < 9
        const originalRange = sel.createRange()
        originalRange.collapse(true)
        sel.createRange().pasteHTML(html)
        if (selectPastedContent) {
          range = sel.createRange()
          range.setEndPoint('StartToStart', originalRange)
          range.select()
        }
      }
    },
    deleteText (e) {
      if (e.path[0].className === 'link clicker-link') {
        e.path[0].innerHTML = ''
      }
    }
  }
}
</script>

<style lang="scss" src="../messages/messages.scss" scoped />
<style lang="scss" src="../../components/textInput/textInput.scss" scoped />
<style lang="scss" src="../messagesCreate/messagesCreate.scss" scoped />
<style lang="scss" src="./messagesCreateGroup.scss" scoped />
