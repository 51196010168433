<template>
  <div class="unanswered">
    <div class="users">
      <div class="users-wrapper">
        <div
          v-for="chat in chats"
          :key="chat.id"
          class="router-link-active router-link-exact-active user"
          :class="chat.selected ? 'user-active' : ''"
          @click="selectChat(chat.id)"
        >
          <div class="user-name">
            {{ chat.name}}
          </div>
          <notifyNumber :number="chat.messages" />
        </div>
      </div>
    </div>
    <div class="chat">
      <superChat
        :chatId="selectedChatId"
        :chatUserName="selectedUserName"
        @readUnanswered="readMessages"
      />
    </div>
  </div>
</template>

<script>
import notifyNumber from '@/components/notifyNumber'
import superChat from '@/components/superChat/superChat'

export default {
  name: 'messagesUnanswered',
  components: {
    notifyNumber,
    superChat
  },
  data () {
    return {
      chats: []
    }
  },
  computed: {
    selectedChatId () {
      const selectedChat = this.chats.find(el => el.selected)
      if (selectedChat) {
        return selectedChat.id
      }
      return false
    },
    selectedUserName () {
      const selectedChat = this.chats.find(el => el.selected)
      if (selectedChat) {
        return selectedChat.name
      }
      return false
    }
  },
  methods: {
    readMessages () {
      const findEl = this.chats.find(el => el.id === this.selectedChatId)
      if (findEl) {
        this.$store.commit(
          'techStore/minusMessages',
          {
            minus: findEl.messages
          }
        )
        findEl.messages = 0
      }
    },
    selectChat (chatId) {
      const oldSelectedI = this.chats.findIndex(el => el.selected)
      if (oldSelectedI !== -1) {
        this.chats[oldSelectedI].selected = false
      }
      const newSelectedI = this.chats.findIndex(el => el.id === chatId)
      if (newSelectedI !== -1) {
        this.chats[newSelectedI].selected = true
      }
    }
  },
  created () {
    fetch(window.host + 'dialogs', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.chats = data.data.map(el => {
        return {
          name: el.client_name,
          id: el.id,
          selected: false,
          messages: el.messages
        }
      })
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })

    this.$nextTick(function () {
      setTimeout(() => {
        this.$store.commit('socketStore/subscribe', {
          channel: `private-dialogs.${this.$store.state.techStore.companyId}`,
          eventName: 'App\\Events\\Dialogs',
          callback: socketData => {
            if (socketData.messages) {
              const findEl = this.chats.find(el => el.id === socketData.dialogId)
              findEl.messages = socketData.messages
            }
          }
        })
      }, 500)
    })
  },
  unmounted () {
    this.$store.commit(
      'socketStore/unsubscribe',
      {
        channel: `private-dialogs.${this.$store.state.techStore.companyId}`
      }
    )
  }
}
</script>

<style lang="scss" src="./messagesUnanswered.scss" scoped />
