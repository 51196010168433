<template>
  <xTable
    v-if="tableData.data.length"
    v-bind="tableData"
    @onPageChange="requestData"
  />
  <div v-else class="empty-list">
    Список рекоммендаций этого клиента пока пуст
  </div>
</template>

<script>
import xTable from '@/components/xTable'

export default {
  name: 'customerRecommendations',
  components: {
    xTable
  },
  data () {
    return {
      tableData: {
        dateTwoStroke: true,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [],
        wordChecker: true,
        optionsX: [
          { name: 'Дата подключения', value: 'date', id: 0 },
          { name: 'Клиент по рекомендации', value: 'operation', id: 1 },
          { name: 'Уровень', value: 'bonusDeleteNumber', id: 2 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Дата подключения', 'Клиент по рекомендации', 'Уровень'],
        namesForHeaderX: []
      }
    }
  },
  methods: {
    requestData (page = false) {
      const pageParts = []

      if (page) {
        pageParts.push(`page=${page}`)
      }

      const levelId = this.$router.currentRoute.value.query.level
      if (levelId) {
        pageParts.push(`level=${levelId}`)
      }

      const partsUrl = pageParts.length ? `?${pageParts.join('&')}` : ''

      const id = this.$router.currentRoute.value.params.id

      fetch(`${window.host}clients/${id}/recommends/${partsUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!data.success) {
          this.$router.push({
            name: 'error'
          })
        }

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        const recommends = data.data.recommends
        this.tableData.data = recommends.map(el => ({
          date: el.created_at || '─',
          operation: el.name || '─',
          bonusDeleteNumber: el.level || '0'
        }))

        const paginate = data.data.paginate
        this.tableData.totalPagesX = paginate.last_page
        this.tableData.pageSizeX = paginate.per_page
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    }
  },
  created () {
    this.requestData()

    const id = this.$router.currentRoute.value.params.id
    fetch(`${window.host}clients/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      const userData = data.data

      this.$emit('titleName', userData.name)
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./customerRecommendations.scss" scoped />
