<template>
  <accountContent title="Восстановление пароля" desc="Укажите новый пароль">
    <div class="input-container">
      <form action="javascript:void(0)">
        <div v-if="!!formError" class="error mb-20">
          {{ formError }}
        </div>

        <div class="x-input-item">
          <div class="input-name">Пароль</div>
          <div class="input-content" :class="passwordError && 'error-wrap'">
            <textInput
              autocomplete="new-password"
              type="password"
              placeholder="****************"
              v-bind:defaultValue="password"
              @onChange="onChangePassword"
            />
          </div>
        </div>
        <div v-if="!!passwordError" class="error error-input mb-20 error-right">
          {{ passwordError }}
        </div>
        <div class="x-input-item">
          <div class="input-name">Повторите пароль</div>
          <div class="input-content" :class="c_passwordError && 'error-wrap'">
            <textInput
              autocomplete="new-password"
              type="password"
              placeholder="****************"
              v-bind:defaultValue="c_password"
              @onChange="onChangeRepeatPassword"
            >
            </textInput>
          </div>
        </div>
        <div v-if="!!c_passwordError" class="error error-input error-right">
          {{ c_passwordError }}
        </div>
      </form>
    </div>
    <div class="buttons">
      <div class="reg-button">
        <xButton @click="onSendForm">
          Сохранить
        </xButton>
      </div>
    </div>
  </accountContent>
</template>

<script>
import accountContent from '@/components/accountContent'
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'

export default {
  name: 'accountNew',
  components: {
    accountContent,
    textInput,
    xButton
  },
  data () {
    return {
      formError: '',
      token: '',
      email: '',
      password: '',
      c_password: '',
      passwordError: '',
      c_passwordError: ''
    }
  },
  methods: {
    onChangePassword (val) {
      this.password = val
      if (val.length < 8) {
        this.passwordError = 'Минимальная длинна пароля 8 символов'
      } else {
        this.passwordError = ''
      }
    },
    onChangeRepeatPassword (val) {
      this.c_password = val
      if (val.length < 8) {
        this.c_passwordError = 'Минимальная длинна пароля 8 символов'
      } else {
        if (val !== this.password) {
          this.c_passwordError = 'Пароли не совпадают'
        } else {
          this.c_passwordError = ''
        }
      }
    },
    async onSendForm () {
      if (!this.password.length || !this.c_password.length || this.passwordError.length || this.c_passwordError.length) {
        if (!this.password.length && !this.passwordError.length) {
          this.passwordError = 'Минимальная длинна пароля 8 символов'
        }
        if (!this.c_password.length && !this.c_passwordError.length) {
          this.c_passwordError = 'Минимальная длинна пароля 8 символов'
        }
        return
      } else {
        this.formError = ''
      }

      await fetch(window.host + 'users/password/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: this.token,
          email: this.email,
          password: this.password,
          c_password: this.c_password
        })
      }).then(async response => {
        const data = await response.json()

        if (!response.ok) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.$store.commit(
          'popupStore/show',
          {
            title: 'Восстановление пароля',
            text: 'Пароль успешно изменен',
            buttonText: 'Авторизоваться',
            callback: async () => {
              await this.$router.push('/login')
            }
          }
        )
      }).catch(err => {
        this.formError = err
        this.$store.commit('techStore/checkError', { err })
      })
    }
  },
  async created () {
    this.$store.commit('techStore/checkLogin', { path: 'admin' })

    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    const email = urlParams.get('email')
    if (!token || !email) {
      await this.$router.push('/login')
    } else {
      this.token = token
      this.email = email
    }
  }
}
</script>

<style lang="scss" src="./accountPasswordReset.scss" scoped />
