<template>
  <accountContent title="Создать аккаунт для своего бизнеса" desc="Получите бесплатный доступ ко всему функционалу на период запуска">
    <div>
      <form action="javascript:void(0)">
        <div v-if="!!formError" class="error">
          {{ formError }}
        </div>

        <div class="x-input-item">
          <div class="input-name">Имя</div>
          <div class="input-content" :class="errors.name && 'error-wrap'">
            <textInput
              placeholder="Иван"
              v-bind:defaultValue="name"
              @onChange="onChangeName"
            />
          </div>
        </div>
        <div v-if="!!errors.name" class="error error-input">
          {{ errors.name }}
        </div>
        <div class="x-input-item">
          <div class="input-name">Телефон</div>
          <div class="input-content phone-option-container" :class="errors.phone && 'error-wrap'">
            <div class="input-wrapper">
              <div class="input-container">
                <input
                  class="input ml-40"
                  type="tel"
                  :value="phone"
                  v-mask="phoneMask"
                  :placeholder="selectedPhoneTemplate ? selectedPhoneTemplate.code : ''"
                  @input="event => onChangePhone(event.target.value)"
                >
              </div>
            </div>
            <div class="phone-options">
              <div class="phone-select">
                <div class="option selected" @click="openPhones">
                  <div v-if="selectedPhoneTemplate" class="flag">
                    <img :src="selectedPhoneTemplate.icon" :alt="selectedPhoneTemplate.country">
                  </div>
                  <div class="arrow-down" :class="isPhoneTemplatesOpen ? 'active' : 'disactive'">
                    <icon name="arrow"/>
                  </div>
                </div>
                <div class="options" :class="isPhoneTemplatesOpen ? 'show' : ''">
                  <div
                    v-for="template in phoneTemplates"
                    :key="template"
                    class="option"
                    @click="selectedPhones(template.id)"
                  >
                    <div class="flag">
                      <img :src="template.icon" :alt="template.country">
                    </div>
                    <div class="county">{{ template.country }}</div>
                    <div class="code">{{ template.code }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!!errors.phone" class="error error-input">
          {{ errors.phone }}
        </div>
        <div class="x-input-item">
          <div class="input-name">Сфера деятельности</div>
          <div class="input-content" :class="errors.activityId && 'error-wrap'">
            <Multiselect
              v-model="activityId"
              :options="activity"
              :canClear="false"
              @change="onChangeActivity()"
            />
          </div>
        </div>
        <div v-if="!!errors.activityId" class="error error-input">
          {{ errors.activityId }}
        </div>
        <div class="x-input-item">
          <div class="input-name">
            <span class="note-wrapper">
              Логин
              <note note-position="bottom-to-right">
                Используйте работающий электронный адрес, к которому есть доступ только у вас.
              </note>
            </span>
          </div>
          <div class="input-content" :class="errors.email && 'error-wrap'">
            <textInput
              placeholder="example@mail.ru"
              autocomplete="mail username"
              v-bind:defaultValue="email"
              @onChange="onChangeEmail"
            />
          </div>
        </div>
        <div v-if="!!errors.email" class="error error-input">
          {{ errors.email }}
        </div>
        <div class="x-input-item">
          <div class="input-name">
            <span class="note-wrapper">
              Пароль
              <note note-position="bottom-to-right">
                Пaроль должен содержать не менее 8 символов. Минимум 1 заглавная буква. Минимум 1 цифра. Минимум 1 спецсимвол (! @ $ % и т.п.)
              </note>
            </span>
          </div>
          <div class="input-content" :class="errors.password && 'error-wrap'">
            <textInput
              autocomplete="new-password"
              :type="isShowPassword ? 'text' : 'password'"
              placeholder="••••••••••••••••"
              v-bind:defaultValue="password"
              @onChange="onChangePassword"
            >
              <template v-slot:after>
                <eye :onClick="showPassword"/>
              </template>
            </textInput>
          </div>
        </div>
        <div v-if="!!errors.password" class="error error-input">
          {{ errors.password }}
        </div>
        <div class="x-input-item">
          <div class="input-name">Повторите пароль</div>
          <div class="input-content" :class="errors.c_password && 'error-wrap'">
            <textInput
              autocomplete="new-password"
              :type="isShowC_password ? 'text' : 'password'"
              placeholder="••••••••••••••••"
              v-bind:defaultValue="c_password"
              @onChange="onChangeRepeatPassword"
            >
              <template v-slot:after>
                <eye :onClick="showRepeatPassword"/>
              </template>
            </textInput>
          </div>
        </div>
        <div v-if="!!errors.c_password" class="error error-input">
          {{ errors.c_password }}
        </div>
        <div class="agreements">
          <div class="agreements-item" :class="errors.politic && 'error'">
            <checkboxInput color="white-bg" :checked="politic" @isChecked="changePolitic"/>
            <div class="agreements-text">
              Я ознакомился с <a href="" class="link agreements-link">Политикой конфиденциальности</a> и принимаю её условия
            </div>
          </div>
          <div v-if="!!errors.politic" class="error error-input error-left">
            {{ errors.politic }}
          </div>
          <div class="agreements-item" :class="errors.offerta && 'error'">
            <checkboxInput color="white-bg" :checked="offerta" @isChecked="changeOfferta"/>
            <div class="agreements-text">
              Я ознакомился и принимаю <a href="" class="link agreements-link">Условия оферты</a>
            </div>
          </div>
          <div v-if="!!errors.offerta" class="error error-input error-left">
            {{ errors.offerta }}
          </div>
        </div>
        <div class="buttons">
          <div class="reg-button">
            <xButton @click="onSendForm" :disabled="isButtonDisabled">
              Зарегистрироваться
            </xButton>
          </div>
          <router-link to="/login" class="link button-link">Уже есть аккаунт? Войти</router-link>
        </div>
      </form>
    </div>
  </accountContent>
</template>

<script>
import accountContent from '@/components/accountContent'
import textInput from '@/components/textInput'
import checkboxInput from '@/components/checkboxInput'
import xButton from '@/components/xButton'
import note from '@/components/note'
import Note from '@/components/note/note'
import eye from '@/components/eye'

export default {
  name: 'accountNew',
  components: {
    Note,
    accountContent,
    textInput,
    checkboxInput,
    xButton,
    note,
    eye
  },
  data () {
    return {
      isShowPassword: false,
      isShowC_password: false,
      activityStore: [],
      formError: '',
      activityId: '',
      activity: [],
      name: '',
      phone: '',
      email: '',
      password: '',
      c_password: '',
      politic: false,
      offerta: false,
      errors: {
        activityId: '',
        name: '',
        phone: '',
        email: '',
        password: '',
        c_password: '',
        politic: '',
        offerta: ''
      },
      isPhoneTemplatesOpen: false,
      phoneTemplatesSelectedId: 0,
      phoneTemplates: []
    }
  },
  computed: {
    phoneMask () {
      const phoneTemplate = this.selectedPhoneTemplate
      if (!phoneTemplate) {
        return ''
      }
      const endMask = '###-##-##'
      const hooks = '#'.repeat(5 - phoneTemplate.code.length)

      return phoneTemplate.code + ' (' + hooks + ') ' + endMask
    },
    selectedPhoneTemplate () {
      return this.phoneTemplates.find(el => el.id === this.phoneTemplatesSelectedId)
    },
    selectedActivityId () {
      const res = this.activityStore.find(el => el.name === this.activityId)
      if (res) {
        return res.id
      }

      return false
    },
    isHasErrors () {
      return Object.values(this.errors).join('').length
    },
    isCheckedCheckboxes () {
      return !!this.politic && !!this.offerta
    },
    isButtonDisabled () {
      return this.isHasErrors || !this.isCheckedCheckboxes
    },
    isPasswordValid () {
      const upSymbol = /([A-Z]|[А-ЯЁ])/gmu.test(this.password)
      const numberExist = /[0-9]/gmu.test(this.password)
      const noSymbol = /\W/gmu.test(this.password)

      return upSymbol && numberExist && noSymbol
    }
  },
  methods: {
    openPhones () {
      this.isPhoneTemplatesOpen = !this.isPhoneTemplatesOpen
    },
    selectedPhones (id) {
      this.isPhoneTemplatesOpen = false
      this.phoneTemplatesSelectedId = id
      this.phone = this.phoneMask
    },
    showPassword () {
      this.isShowPassword = !this.isShowPassword
    },
    showRepeatPassword () {
      this.isShowC_password = !this.isShowC_password
    },
    setEmptyErrors () {
      for (const key of ['name', 'phone', 'email', 'password', 'c_password', 'activityId']) {
        if (!this[key].length && !this.errors[key].length) {
          this.errors[key] = 'Заполните поле'
        }
      }
    },
    setNotSettedCheckboxesErrors () {
      if (!this.politic) {
        this.errors.politic = 'Нужно согласие с правилами конфиденциальности'
      }
      if (!this.offerta) {
        this.errors.offerta = 'Нужно согласие с условиями оферты'
      }
    },
    changePolitic (val) {
      this.politic = val
      this.errors.politic = ''
    },
    changeOfferta (val) {
      this.offerta = val
      this.errors.offerta = ''
    },
    onChangeName (val) {
      this.errors.name = ''
      this.name = val
    },
    onChangeActivity (val = false) {
      this.errors.activityId = ''
      this.activityId = val
    },
    onChangePhone (val) {
      const correctPhone = val.replace(this.selectedPhoneTemplate.code, '')
      if (this.phone !== correctPhone) {
        this.phone = correctPhone
      }

      if (correctPhone.replace(this.selectedPhoneTemplate.code, '').replace(/\D/gum, '').length === 10) {
        this.errors.phone = ''

        if (this.formError === 'Пользователь с таким номером телефона уже существует') {
          this.formError = ''
        }
      }
    },
    onChangeEmail (val) {
      this.email = val
      // eslint-disable-next-line no-useless-escape
      if (val.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        this.errors.email = ''
        if (this.formError === 'Пользователь с таким email уже существует') {
          this.formError = ''
        }
      }
    },
    onChangePassword (val) {
      this.password = val
      if (val.length >= 8) {
        this.errors.password = ''
      }
    },
    onChangeRepeatPassword (val) {
      this.c_password = val
      if (val.length >= 8 && val === this.password) {
        this.errors.c_password = ''
      }
    },
    validateForm () {
      if (this.selectedPhoneTemplate.code + this.phone.replace(this.selectedPhoneTemplate.code, '').replace(/\D/gum, '').length < 11) {
        this.errors.phone = 'Укажите правильно телефон'
      }

      // eslint-disable-next-line no-useless-escape
      if (!this.email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        this.errors.email = 'Укажите корректный e-mail'
      }

      if (this.password.length < 8) {
        this.errors.password = 'Минимальная длинна пароля 8 символов'
      } else if (!this.isPasswordValid) {
        this.errors.password = 'Пaроль должен содержать: минимум 1 заглавную букву, минимум 1 цифру, минимум 1 спецсимвол (! @ $ % и т.п.)'
      }

      if (this.c_password.length < 8) {
        this.errors.c_password = 'Минимальная длинна пароля 8 символов'
      } else {
        if (this.c_password !== this.password) {
          this.errors.c_password = 'Пароли не совпадают'
        }
      }

      if (parseInt(this.selectedActivityId) === 0) {
        this.errors.activityId = 'Выберите сферу деятельности'
      }
    },
    async onSendForm () {
      this.setEmptyErrors()
      this.setNotSettedCheckboxesErrors()
      this.validateForm()

      if (this.isButtonDisabled) {
        return
      } else {
        this.formError = ''
      }

      await fetch(window.host + 'users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          activityId: this.selectedActivityId,
          name: this.name,
          phone: this.selectedPhoneTemplate.code + this.phone,
          email: this.email,
          password: this.password,
          c_password: this.c_password
        })
      }).then(async response => {
        const data = await response.json()

        if (!response.ok) {
          const error = ((data && data.data) && data.data) || (data && data.message) || response.status
          return Promise.reject(error)
        }

        const url = window.domen + '/api/oauth/token'
        const code = {
          grant_type: 'password',
          client_id: '4',
          client_secret: 'UB2BeuYwwaD9U8aM5PTwUfabvvECsL24mj7TqQmV',
          username: this.email,
          password: this.password
        }

        await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(code)
        }).then(async response => {
          const data = await response.json()

          if (!response.ok) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          // eslint-disable-next-line
          const { access_token, refresh_token } = data
          localStorage.setItem('access_token', access_token)
          localStorage.setItem('refresh_token', refresh_token)

          this.$store.commit('techStore/doAuth')
          await this.$router.push('instruction')
        }).catch(err => {
          this.formError = err
          this.$store.commit('techStore/checkError', { err })
        })
      }).catch(err => {
        let customError = err
        if (err.email && err.email[0] === 'Пользователь с таким email уже существует') {
          customError = err.email[0]
          this.$store.commit(
            'popupStore/show',
            {
              text: err.email[0],
              callback: async () => {
                await this.$router.push('/login')
              },
              buttonText: 'Войти',
              useFullWindow: true
            }
          )
        }
        if (err.phone && err.phone[0] === 'Пользователь с таким номером телефона уже существует') {
          customError = err.phone[0]
          this.$store.commit(
            'popupStore/show',
            {
              text: err.phone[0],
              callback: async () => {
                await this.$router.push('/login')
              },
              buttonText: 'Войти',
              useFullWindow: true
            }
          )
        }
        this.formError = customError
        this.$store.commit('techStore/checkError', { err })
      })
    }
  },
  created () {
    this.$store.commit('techStore/checkLogin', { path: 'admin' })

    fetch(window.privateHost + 'activities', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then(async response => {
      const data = await response.json()
      const dataArray = []

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      dataArray.push({
        id: '0',
        name: 'Не выбрано'
      });

      Array.prototype.push.apply(dataArray, data.data)

      this.activityStore = dataArray

      this.activity = dataArray.map(el => el.name)
      this.activityId = this.activity[0]
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })

    fetch(window.host + 'phones/public/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.phoneTemplates = data.data.filter(el => el.active === 1).map(el => {
        return {
          id: el.id,
          icon: window.domen + el.icon,
          country: el.country,
          code: el.code
        }
      })
      if (this.phoneTemplates.length) {
        this.phoneTemplatesSelectedId = this.phoneTemplates[0].id
      }

      this.phone = this.phoneMask
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./accountNew.scss" scoped />
