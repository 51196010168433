<template>
  <pop-up
    :open="this.$store.state.popupStore.isShow"
    sizer="pop-small"
    :additionalClass="getCustomClass"
    :iconClose="!this.$store.state.popupStore.blockClose"
    @close="closeAction"
  >
    <h2
      class="x-pop-title"
      :class="this.$store.state.popupStore.useMiddleTitle ? 'middle-text' : ''"
    >
      {{ this.$store.state.popupStore.title }}
    </h2>
    <p v-if="this.$store.state.popupStore.text" class="x-pop-desc-black">
      {{ this.$store.state.popupStore.text }}
    </p>
    <div
      v-if="!this.$store.state.popupStore.blockClose"
      class="x-pop-footer"
      :class="this.$store.state.popupStore.rollBackButtonText ? 'double' : ''"
    >
      <xButton
        v-if="this.$store.state.popupStore.rollBackButtonText"
        additionalClass="x-button-white"
        @click="this.$store.commit('popupStore/errorAction')"
      >
        {{ this.$store.state.popupStore.rollBackButtonText }}
      </xButton>
      <xButton additionalClass="x-button-mr" @click="this.$store.commit('popupStore/action')">
        {{ this.$store.state.popupStore.buttonText }}
      </xButton>
    </div>
  </pop-up>
</template>

<script>
import popUp from '@/components/popUp'
import xButton from '@/components/xButton'

export default {
  name: 'errorPopup',
  components: {
    popUp,
    xButton
  },
  props: {
    additionalClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    getCustomClass () {
      return [
        'w-100',
        this.additionalClass ? this.additionalClass : '',
        this.$store.state.popupStore.useFullWindow ? 'full-window' : ''
      ].join(' ')
    }
  },
  methods: {
    closeAction () {
      if (!this.$store.state.popupStore.blockClose) {
        this.$store.commit('popupStore/close')
      }
    }
  }
}
</script>

<style lang="scss" src="./errorPopup.scss" scoped />
