<template>
  <div class="messages-wrapper">
    <div class="messages-header">
      <template v-if="chatDisabled">
        <div class="disabled">
          <p class="disabled-text">
            Чат с клиентом отключен. Для включения перейдите в <router-link to="/admin/settings" class="link">Настройки.</router-link>
          </p>
        </div>
      </template>
<!--      <template v-else>-->
<!--        <div class="timer">-->
<!--          <p class="timer-text">-->
<!--            Среднее время ответа: <span class="timer-average">1 час 30 минут</span>-->
<!--          </p>-->
<!--        </div>-->
<!--      </template>-->
    </div>
    <div class="chat" v-if="!chatDisabled">
      <superChat
        errorMessage="Этот пользователь не привязан к мессенджеру"
        :chatId="chatId"
        :chatUserName="this.$store.state.techStore.titleName"
      />
    </div>
  </div>
</template>

<script>
import superChat from '@/components/superChat/superChat'

export default {
  name: 'customerMessages',
  components: {
    superChat
  },
  data () {
    return {
      chatId: 0,
      chatDisabled: ''
    }
  },
  created () {
    this.$nextTick(function () {
      const clientId = this.$router.currentRoute.value.params.id

      fetch(`${window.host}clients/${clientId}/dialog/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (data.data && data.data.dialog) {
          this.chatId = data.data.dialog
        }
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    })

    fetch(window.host + 'users/profile', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }
      const userData = data.data.user
      this.chatDisabled = !userData.chat
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })

    const id = this.$router.currentRoute.value.params.id
    fetch(`${window.host}clients/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      const userData = data.data

      this.$emit('titleName', userData.name)
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./customerMessages.scss" scoped />
