<template>
  <div class="clients-header">
    <div class="clients-filter">
      <xFilter
        :rowCount="rowCount"
        @onExcelExport="onExcelExport"
        @apply="requestCashiers"
        @reset="resetValues"
      >
        <xFilterItem
          v-for="item in tags"
          :key="item.id"
          :inputNumber="item.number"
          :item="item"
          @logChange="filtering"/>
      </xFilter>
    </div>
    <div class="clients-search">
      <div class="input-wrapper">
        <div class="input-container">
          <button class="search-button">
            <icon class="search-icon" name="search"/>
          </button>
          <input
            class="input"
            autocomplete="off"
            placeholder="Введите фамилию кассира..."
            @input="e => searchString(e.target.value)"
          >
        </div>
      </div>
    </div>
    <div class="clients-create">
      <xButton @click="popUpCashier = true">
        <icon class="xbutton-icon" name="create"></icon>
        Создать
      </xButton>
    </div>
  </div>
  <tags :tags="tags" :tagsCount="tagsCount" @deleteTag="deletingTag"/>
  <xTable
    v-if="tableData.data.length"
    v-bind="tableData"
    @onSort="sortAction"
    @onPageChange="requestCashiersByPage"
  >
    <template v-slot="{ records }">
      <td>
        <div class="table-action">
          <button @click="editCashierPopupData(records.id)">
            <icon name="edit" class="table-icon" />
          </button>
          <button @click="deleteCashierPopupData(records.id)">
            <icon name="delete" class="table-icon" />
          </button>
        </div>
      </td>
    </template>
  </xTable>
  <div v-else class="empty-list">
    Список кассиров пока пуст
  </div>
  <pop-up :open="popUpCashier" @close="closeCashierPopupData" :center="true">
    <h2 class="x-pop-title">Создание кассира</h2>
    <div class="x-input-item">
      <div class="input-name">Имя</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="{'input-validate': cashierRequiredErrors.name}">
            <input
              class="input"
              placeholder="Введите имя"
              v-model="cashierPopupData.name"
              @input="() => eraseRequiredError('name')"
            >
            <template v-if="cashierRequiredErrors.name">
              <div class="validate-icon">
                <icon name="validate"/>
              </div>
            </template>
          </div>
          <div v-if="cashierRequiredErrors.name" class="error">
            Заполните поле
          </div>
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">
        <span class="note-wrapper">
          Логин <br> (эл. почта)
        <note note-position="bottom-to-right">
          <b>
            Сплочённость команды профессионалов продолжает удивлять.
          </b>
          <br><br>
          Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний, из которых в опредёленном порядке формируются предложения.
          <br><br>
          Предложения складываются в абзацы – и вы наслаждетесь очередным бредошедевром.
          <br><br>
          Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей"
        </note>
        </span>
      </div>
      <div class="input-content">
        <div class="input-container" :class="{'input-validate': emailError || cashierRequiredErrors.login}">
          <input
            class="input"
            placeholder="Введите почту"
            v-model="cashierPopupData.login"
            @input="checkEmail"
          >
          <template v-if="emailError || cashierRequiredErrors.login">
            <div class="validate-icon">
              <icon name="validate"/>
            </div>
          </template>
        </div>
        <div v-if="emailError || cashierRequiredErrors.login" class="error">
          {{ emailError ? emailError : 'Заполните поле'}}
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Пароль</div>
      <div class="input-content">
        <div class="input-container" :class="{'input-validate': passwordError || cashierRequiredErrors.password}">
          <input
            class="input"
            :type="isShowPassword ? 'text' : 'password'"
            placeholder="Введите пароль"
            v-model="cashierPopupData.password"
            @input="checkPassword"
          >
          <eye :onClick="showPassword"/>
          <template v-if="passwordError || cashierRequiredErrors.password">
            <div class="validate-icon">
              <icon name="validate"/>
            </div>
          </template>
        </div>
        <div v-if="passwordError || cashierRequiredErrors.password" class="error">
          {{ passwordError ? passwordError : 'Заполните поле'}}
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Филиал</div>
      <div class="input-content">
        <Multiselect
          v-model="selectedBranchName"
          :options="branch"
          :canClear="false"
        />
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Внеш. индикатор</div>
      <div class="input-content">
        <div class="input-container" :class="{'input-validate': cashierRequiredErrors.externalId}">
          <input
            class="input"
            placeholder="Введите индикатор"
            v-model="cashierPopupData.externalId"
            @input="() => eraseRequiredError('externalId')"
          >
          <template v-if="cashierRequiredErrors.externalId">
            <div class="validate-icon">
              <icon name="validate"/>
            </div>
          </template>
        </div>
        <div v-if="cashierRequiredErrors.externalId" class="error">
          Заполните поле
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Комментарий</div>
      <div class="input-content">
        <div class="input-container">
          <textarea
            class="input textarea"
            placeholder="Введите комментарий"
            v-model="cashierPopupData.comment"
          >
          </textarea>
        </div>
      </div>
    </div>
    <xButton @click="saveCashierData">Сохранить</xButton>
  </pop-up>
  <pop-up :open="popUpDelete" @close="closeDeletePopup" sizer="pop-small">
    <h2 class="x-pop-title">Вы удаляете кассира</h2>
    <p class="x-pop-desc-black">
      После удаления использование его учетных данных для проведения операций будет невозможно.
      <br>
      Подтвердите удаление
    </p>
    <div class="x-pop-footer x-pop-footer__small">
      <xButton @click="deleteAction">Удалить</xButton>
      <button @click="closeDeletePopup" class="link x-pop-back">Отмена</button>
    </div>
  </pop-up>
</template>

<script>
import xFilter from '@/components/xFilter/xFilter'
import xTable from '@/components/xTable/xTable'
import XButton from '@/components/xButton/xButton'
import popUp from '@/components/popUp'
import note from '@/components/note'
import debounce from 'lodash.debounce'
import tags from '@/components/tags'
import xFilterItem from '@/components/xFilterItem'
import eye from '@/components/eye'

const searchByString = debounce((callback) => {
  callback()
}, 1000)

export default {
  name: 'team',
  components: {
    XButton,
    xFilter,
    xTable,
    popUp,
    note,
    tags,
    xFilterItem,
    eye
  },
  data () {
    return {
      cashierPopupData: {
        id: 0,
        name: '',
        login: '',
        password: '',
        externalId: '',
        comment: ''
      },
      cashierRequiredErrors: {
        name: false,
        login: false,
        password: false,
        externalId: false
      },
      emailError: '',
      passwordError: '',
      isShowPassword: false,
      popUpCashier: false,
      popUpDelete: false,
      deleteId: 0,
      selectedBranchName: null,
      originalBranches: [],
      tags: [
        { name: 'Дата создания', type: 'date', code: 'created_at' },
        { name: 'Филиал', type: 'checkbox', number: 0, code: 'office' },
        { name: 'Средний чек', type: 'number', code: 'average' },
        { name: 'Кол-во чеков', type: 'number', code: 'checks' }
      ],
      rowCount: 0,
      tagsCount: 0,
      sort: {
        name: 'Дата создания',
        type: 'desc'
      },
      searchQuery: '',
      originalData: [],
      tableData: {
        showToggler: true,
        showCheckboxes: true,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [],
        wordChecker: true,
        optionsX: [
          { name: 'Кассир', value: 'name', id: 0 },
          { name: 'Дата создания', value: 'regDate', id: 1 },
          { name: 'Филиал', value: 'branch', id: 2 },
          { name: 'Средний чек', value: 'averageCheck', id: 3 },
          { name: 'Кол-во чеков', value: 'checkNumber', id: 4 }
        ],
        allNamesX: ['Кассир', 'Дата создания', 'Филиал', 'Средний чек', 'Кол-во чеков'],
        newNamesX: [],
        importantNamesX: ['Кассир'],
        defaultNamesX: ['Кассир', 'Дата создания', 'Филиал', 'Средний чек', 'Кол-во чеков'],
        namesForHeaderX: [],
        useSort: true,
        defaultSortName: 'Дата создания',
        defaultSort: 'desc'
      }
    }
  },
  computed: {
    branch () {
      return this.originalBranches.map(el => el.name)
    },
    getSelectedBranchId () {
      if (this.selectedBranchName) {
        const finded = this.originalBranches.find(el => el.name === this.selectedBranchName)
        if (finded) {
          return finded.id
        }
      }

      return false
    },
    getFilters () {
      const filter = {}

      for (const i in this.tags) {
        const tag = this.tags[i]

        if (tag.type === 'checkbox') {
          // eslint-disable-next-line no-case-declarations
          const tagValue = []

          let maxI = 0
          for (const key of Object.keys(tag)) {
            const onlyDigits = key.replace(/\D/gmu, '')
            if (onlyDigits.length) {
              const digitsInt = parseInt(onlyDigits)
              if (digitsInt > maxI) {
                maxI = digitsInt
              }
            }
          }

          if (maxI) {
            for (let j = 1; j <= maxI; j++) {
              if (tag[`check${j}`]) {
                tagValue.push(tag[`value${j}`])
              }
            }
          }

          if (tagValue.length) {
            filter[tag.code] = tagValue
          }
        } else {
          if (tag.valueFrom) {
            filter[`${tag.code}_from`] = tag.valueFrom
          }

          if (tag.valueTo) {
            filter[`${tag.code}_to`] = tag.valueTo
          }
        }
      }

      const result = {
        searchQuery: this.searchQuery,
        filter
      }

      const sortField = this.tableData.optionsX.find(el => el.name === this.sort.name)
      if (sortField) {
        result.sort = {
          name: sortField.value,
          type: this.sort.type
        }
      }

      return result
    },
    getPreparedOnlyFilter () {
      const filters = this.getFilters

      const filterForRequest = []
      for (const key in filters.filter) {
        const val = filters.filter[key]
        if (Array.isArray(val)) {
          for (const innerVal of val) {
            filterForRequest.push(`${key}[]=${innerVal}`)
          }
        } else {
          filterForRequest.push(`${key}=${val}`)
        }
      }

      return filterForRequest
    },
    getPreparedFilterForRequest () {
      const filters = this.getFilters

      const filterForRequest = [...this.getPreparedOnlyFilter]

      const searchQuery = filters.searchQuery.trim()
      if (searchQuery.length) {
        filterForRequest.push(`searchQuery=${searchQuery}`)
      }

      const sort = filters.sort
      const sortMapNames = {
        name: 'name',
        regDate: 'created_at',
        branch: 'office_id',
        averageCheck: 'average',
        checkNumber: 'checks'
      }

      filterForRequest.push(`sort=${sortMapNames[sort.name]}`)
      filterForRequest.push(`order=${sort.type}`)

      return filterForRequest
    },
    isCashierDataErrorExist () {
      return Object.values(this.cashierRequiredErrors).includes(true) || this.emailError || this.passwordError
    }
  },
  methods: {
    validateRequiredFields () {
      if (!this.cashierPopupData.name.length) {
        this.cashierRequiredErrors.name = true
      }
      if (!this.cashierPopupData.login.length) {
        this.cashierRequiredErrors.login = true
      }
      if (!this.cashierPopupData.id && !this.cashierPopupData.password.length) {
        this.cashierRequiredErrors.password = true
      }
      if (!this.cashierPopupData.externalId.length) {
        this.cashierRequiredErrors.externalId = true
      }
    },
    saveCashierData () {
      this.validateRequiredFields()
      if (this.isCashierDataErrorExist) {
        return
      }

      const saveObj = {
        office_id: this.getSelectedBranchId,
        name: this.cashierPopupData.name,
        email: this.cashierPopupData.login,
        external_id: this.cashierPopupData.externalId,
        comment: this.cashierPopupData.comment
      }
      if ((this.cashierPopupData.id && this.cashierPopupData.password) || !this.cashierPopupData.id) {
        saveObj.password = this.cashierPopupData.password
      }

      fetch(window.host + 'cashiers/' + (this.cashierPopupData.id ? this.cashierPopupData.id : ''), {
        method: this.cashierPopupData.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify(saveObj)
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.requestCashiers()
        this.requestRowCount()
        this.$store.commit('animateStore/animateSave')
        this.closeCashierPopupData()
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    editCashierPopupData (id) {
      const finded = this.originalData.find(el => el.id === id)
      if (finded) {
        this.popUpCashier = true
        this.cashierPopupData.id = finded.id
        this.cashierPopupData.name = finded.name
        this.cashierPopupData.login = finded.email
        this.cashierPopupData.externalId = finded.external_id
        this.cashierPopupData.comment = finded.comment

        this.selectedBranchName = finded.office.name
      }
    },
    deleteAction () {
      fetch(window.host + 'cashiers/' + this.deleteId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.closeDeletePopup()
        this.requestCashiers()
        this.requestRowCount()
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    closeDeletePopup () {
      this.deleteId = 0
      this.popUpDelete = false
    },
    deleteCashierPopupData (id) {
      this.popUpDelete = true
      this.deleteId = id
    },
    closeCashierPopupData () {
      this.cashierPopupData.id = 0
      this.cashierPopupData.name = ''
      this.cashierPopupData.login = ''
      this.cashierPopupData.password = ''
      this.cashierPopupData.externalId = ''
      this.cashierPopupData.comment = ''
      this.cashierRequiredErrors.name = false
      this.cashierRequiredErrors.login = false
      this.cashierRequiredErrors.password = false
      this.cashierRequiredErrors.externalId = false
      this.emailError = ''
      this.passwordError = ''
      this.isShowPassword = false
      this.popUpCashier = false
    },
    eraseRequiredError (field) {
      this.cashierRequiredErrors[field] = false
    },
    checkEmail () {
      this.eraseRequiredError('login')
      // eslint-disable-next-line no-useless-escape
      if (this.cashierPopupData.login.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        this.emailError = ''
      } else {
        this.emailError = 'Укажите корректную почту'
      }
    },
    checkPassword () {
      this.eraseRequiredError('password')
      if (this.cashierPopupData.password.length >= 8) {
        this.passwordError = ''
      } else {
        this.passwordError = 'Укажите не менее 8 символов'
      }
    },
    showPassword () {
      this.isShowPassword = !this.isShowPassword
    },
    searchString (val) {
      this.searchQuery = val
      searchByString(this.requestCashiers.bind(this))
    },
    setCashiers (arrCashiers) {
      this.tableData.data = arrCashiers.map(el => {
        return {
          id: el.id || '─',
          name: el.name || '─',
          regDate: el.created_at || '─',
          branch: el.office.name || '─',
          averageCheck: el.average || '─',
          checkNumber: el.checks || '─'
        }
      })
    },
    onExcelExport: function () {
      fetch(window.host + 'excel/code', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (!data.data) {
          throw Error('Нет авторизационного кода')
        }

        const preparedFilter = [...this.getPreparedOnlyFilter]
        preparedFilter.push('uid=' + data.data)
        const uri = window.host + 'excel/cashiers?' + preparedFilter.join('&')

        const link = document.createElement('a')
        link.href = uri
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    requestCashiersByPage: function (pageNumber) {
      const preparedFilter = [...this.getPreparedFilterForRequest]
      preparedFilter.push(`page=${pageNumber}`)

      fetch(window.host + 'cashiers?' + preparedFilter.join('&'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.setCashiers(data.data.cashiers)
        this.originalData = data.data.cashiers

        this.tableData.totalPagesX = data.data.paginate.last_page
        this.tableData.pageSizeX = data.data.paginate.per_page
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    requestCashiers: function () {
      const preparedFilter = [...this.getPreparedFilterForRequest]

      fetch(window.host + 'cashiers?' + preparedFilter.join('&'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.setCashiers(data.data.cashiers)
        this.originalData = data.data.cashiers

        this.tableData.totalPagesX = data.data.paginate.last_page
        this.tableData.pageSizeX = data.data.paginate.per_page
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    resetValues: function () {
      this.tagsCount = 0
      for (let i = 0; i < this.tags.length; i++) {
        this.tags[i].value = ''
        this.tags[i].valueTo = ''
        this.tags[i].valueFrom = ''
        this.tags[i].output = ''

        let maxI = 0
        for (const key of Object.keys(this.tags[i])) {
          const onlyDigits = key.replace(/\D/gmu, '')
          if (onlyDigits.length) {
            const digitsInt = parseInt(onlyDigits)
            if (digitsInt > maxI) {
              maxI = digitsInt
            }
          }
        }

        if (maxI) {
          for (let j = 1; j <= maxI; j++) {
            this.tags[i][`output${j}`] = ''
            this.tags[i][`check${j}`] = false
          }
        }
      }

      this.requestCashiers()
    },
    requestRowCount () {
      const preparedFilter = [...this.getPreparedFilterForRequest]
      preparedFilter.push('prefilter=y')

      fetch(window.host + 'cashiers?' + preparedFilter.join('&'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.rowCount = data.data?.count || 0
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    sortAction (obj) {
      this.sort.name = obj.name
      this.sort.type = obj.type

      this.requestCashiers()
    },
    deletingTag (name, direct) {
      this.tagsCount -= 1
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].name === name) {
          this.tags[i].output = ''

          if (this.tags[i].type === 'checkbox') {
            let maxI = 0
            for (const key of Object.keys(this.tags[i])) {
              const onlyDigits = key.replace(/\D/gmu, '')
              if (onlyDigits.length) {
                const digitsInt = parseInt(onlyDigits)
                if (digitsInt > maxI) {
                  maxI = digitsInt
                }
              }
            }

            if (maxI) {
              for (let j = 1; j <= maxI; j++) {
                if (direct === `check${j}` || !direct) {
                  this.tags[i][`check${j}`] = false
                  this.tags[i][`output${j}`] = ''
                }
              }
            }
          } else {
            this.tags[i].valueTo = ''
            this.tags[i].valueFrom = ''
          }
        }
      }

      this.requestCashiers()
    },
    filtering (value, name, direct) {
      if (value === '') {
        this.deletingTag(name)
      } else if (!value) {
        this.deletingTag(name, direct)
      } else {
        this.tagsCount += 1

        const selectedTagIndex = this.tags.findIndex(el => el.name === name)

        if (selectedTagIndex !== -1 && this.tags[selectedTagIndex].type === 'checkbox') {
          const filterName = `${this.tags[selectedTagIndex].name}: `
          const selectedDirect = []

          let maxI = 0
          for (const key of Object.keys(this.tags[selectedTagIndex])) {
            const onlyDigits = key.replace(/\D/gmu, '')
            if (onlyDigits.length) {
              const digitsInt = parseInt(onlyDigits)
              if (digitsInt > maxI) {
                maxI = digitsInt
              }
            }
          }

          if (maxI) {
            for (let i = 1; i <= maxI; i++) {
              const checkKey = `check${i}`
              if (this.tags[selectedTagIndex][checkKey] || checkKey === direct) {
                this.tags[selectedTagIndex][`check${i}`] = true
                selectedDirect.push(this.tags[selectedTagIndex][`text${i}`])
              }
            }
          }

          this.tags[selectedTagIndex].output = `${filterName} ${selectedDirect.join(', ')}`
        } else {
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].name === name) {
              const fromVal = direct === 'from' ? value : (this.tags[i].valueFrom ? this.tags[i].valueFrom : '')
              const toVal = direct === 'to' ? value : (this.tags[i].valueTo ? this.tags[i].valueTo : '')

              this.tags[i].valueFrom = fromVal
              this.tags[i].valueTo = toVal

              const prefixFrom = fromVal ? 'от' : ''
              const prefixTo = toVal ? 'до' : ''

              const output = `${name}: ${prefixFrom} ${fromVal} ${prefixTo} ${toVal}`
              this.tags[i].output = output.replace('  ', ' ')
            }
          }
        }
      }

      this.requestRowCount()
    }
  },
  emits: ['titleName'],
  created () {
    this.$emit('titleName', 'Сотрудники')
    this.requestCashiers()
    this.requestRowCount()

    fetch(window.host + 'users/offices', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.originalBranches = data.data
      if (data.data.length) {
        this.selectedBranchName = data.data[0].name
      }

      const officeIndex = this.tags.findIndex(el => el.code === 'office')
      if (officeIndex) {
        this.originalBranches.map((officeData, i) => {
          this.tags[officeIndex][`text${i + 1}`] = officeData.name
          this.tags[officeIndex][`value${i + 1}`] = officeData.id
          return officeData;
        })

        this.tags[officeIndex].number = this.originalBranches.length
      }
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="./team.scss" scoped/>
<style lang="scss" src="./../../components/textInput/textInput.scss" scoped/>
