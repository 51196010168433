<template>
  <div class="note-container" :class="[iconSize]">
    <div class="note-content">
      <div class="note-icon-wrap">
        <icon class="note-icon" name="note"/>
      </div>
      <div :class="[textAlign, notePosition]" class="note-popup">
        <div class="note-popup-container">
          <slot />
          <p v-html="html"></p>
          <div v-if="hasCommands" >
            <p v-for="command in commands" :key="command.id">
              <input @click="insertText('<span contenteditable=\'false\' class=\'link clicker-link\'>@' + command.name + '</span>')" class="link clicker-tech-link" type="button" v-bind:value="'@'+command.name"> – {{command.text}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'note',
  props: {
    textAlign: {
      validator (value) {
        return ['text-left', 'text-center'].includes(value)
      }
    },
    notePosition: {
      validator (value) {
        return ['bottom-to-left', 'bottom-to-right'].includes(value)
      }
    },
    iconSize: {
      validator (value) {
        return ['note-small', 'note-big'].includes(value)
      }
    },
    html: {
      type: String,
      default: ''
    },
    commands: {
      type: Array,
      default: Array
    },
    getRef: {
      type: Function,
      default: () => ({})
    },
    hasCommands: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    insertText (html, selectPastedContent) {
      const ref = this.getRef();
      ref.focus();

      let sel, range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0)
          range.deleteContents()
          const el = document.createElement('div')
          el.innerHTML = html
          const frag = document.createDocumentFragment(); let node; let lastNode
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node)
          }
          const firstNode = frag.firstChild
          range.insertNode(frag)

          if (lastNode) {
            range = range.cloneRange()
            range.setStartAfter(lastNode)
            if (selectPastedContent) {
              range.setStartBefore(firstNode)
            } else {
              range.collapse(true)
            }
            sel.removeAllRanges()
            sel.addRange(range)
          }
        }
      } else if ((sel = document.selection) && sel.type !== 'Control') {
        // IE < 9
        const originalRange = sel.createRange()
        originalRange.collapse(true)
        sel.createRange().pasteHTML(html)
        if (selectPastedContent) {
          range = sel.createRange()
          range.setEndPoint('StartToStart', originalRange)
          range.select()
        }
      }
    }
  }
}
</script>

<style lang="scss" src="./note.scss" scoped/>
