<template>
  <div class="msg-header">
    <div class="msg-header-button">
      <xButton>
        <icon class="xbutton-icon" name="create"></icon>
        Создать
      </xButton>
    </div>
    <div class="msg-header-notify">
      <adminNotify color="orange" >
        <span class="note-wrapper">
          Не рекомендуем создавать избыточное количество сообщений без бонусов – нет клиентов, которые любят спам
          <note>
            Информируйте клиентов о ВАЖНЫХ ДЛЯ НИХ событиях и новостях. У вас поступление новой коллекции? Вы запустили выгодную акцию? Вы открыли новую точку продаж? Проинформируйте об этом клиентов.
          </note>
        </span>
      </adminNotify>
    </div>
  </div>
  <div class="table-container">
    <xTable v-bind="getTableData()">
      <template  v-slot="{ records }" >
        <td>
          <div class="table-action">
            <router-link to="/customer">
              <icon @click="slotProps(records, 'Редактировать')" name="edit" class="table-icon" />
            </router-link>
            <button @click="slotProps(records, 'Копировать')">
              <icon name="copy" class="table-icon" />
            </button>
            <button @click="slotProps(records, 'Удалить')">
              <icon name="delete" class="table-icon" />
            </button>
          </div>
        </td>
        <td>
          <div class="msg-news-send">
            <div v-if="msgSent">
              <div class="msg-news-date">Отправлено 27.04.2021 в 10:00</div>
              <div class="msg-news-total">
                <div class="msg-news-total-container">
                  <div class="msg-news-total-item">
                    Отправлено — <span class="msg-news-number">1000</span>
                  </div>
                  <div class="msg-news-total-item">
                    Доставлено — <span class="msg-news-number">853</span>
                  </div>
                  <div class="msg-news-total-item">
                    Открыто — <span class="msg-news-number">401</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="msg-news-send-button">
              <xButton @click="send">Отправить</xButton>
            </div>
          </div>
        </td>
      </template>
    </xTable>
  </div>
</template>

<script>
import xTable from '@/components/xTable'
import xButton from '@/components/xButton'
import messagesNews from '@/views/messagesNews/messagesNews.json'
import adminNotify from '@/components/adminNotify'
import note from '@/components/note'

export default {
  name: 'messagesNews',
  components: {
    xTable,
    xButton,
    adminNotify,
    note
  },
  data () {
    return {
      msgSent: false
    }
  },
  methods: {
    getTableData () {
      return {
        theads: 2,
        messagesEvents: true,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: messagesNews,
        wordChecker: true,
        align: 'left',
        optionsX: [
          { name: 'Наименование сообщения', value: 'message', id: 0 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Наименование сообщения'],
        namesForHeaderX: []
      }
    },
    send () {
      this.msgSent = !this.msgSent
    }
  }
}
</script>

<style lang="scss" src="./messagesNews.scss" scoped />
