<template>
  <adminHeader :titleOld="titleOld" :loading="loading"/>
  <sidebar />
  <div class="page_body">
    <div class="container">
      <router-view @titleName="handleName"/>
    </div>
  </div>
  <helper />
  <errorPopup/>
  <save-popup/>
</template>

<script>
import savePopup from '@/components/savePopup'
import adminHeader from '@/components/adminHeader'
import sidebar from '@/components/sidebar'
import helper from '@/components/helper'
import errorPopup from '@/components/errorPopup'

export default {
  name: 'admin',
  components: {
    adminHeader,
    sidebar,
    helper,
    savePopup,
    errorPopup
  },
  data () {
    return {
      titleOld: '',
      loading: false
    }
  },
  methods: {
    handleName (name) {
      if (name === this.titleOld) {
        this.loading = false
      } else {
        this.loading = true
        setTimeout(function (scope) {
          scope.loading = false
        }, 100, this)
      }
      this.titleOld = name
      this.$store.commit(
        'techStore/setTitleName',
        {
          name
        }
      )
    }
  },
  created () {
    this.$store.commit('socketStore/start')
  }
}
</script>

<style scoped src="./admin.scss" lang="scss" />
